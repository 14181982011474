<template>
  <v-menu
    :close-on-content-click="false"
    v-model="showCalendar"
    transition="scale-transition"
    offset-y
    nudge-bottom="5"
    max-width="490px"
    min-width="290px"
  >
    <template v-slot:activator="{ props: localProps }">
      <v-btn
        v-bind="localProps"
        :class="btnStyles"
        class="btn"
        :color="`${isThemeDark ? 'secondary' : undefined}`"
        size="small"
      >
        <v-icon size="16" density="compact" class="mr-1">mdi-calendar</v-icon>
        {{ props.data.title }}
        {{ props.data?.selected?.[0]?.text || "" }}
        <v-icon v-if="props.data?.selected?.[0]?.text" @click.stop.prevent="clearValue" size="13" class="ml-2 mr-n2"
          >mdi-close</v-icon
        >
      </v-btn>
    </template>
    <div class="calendar-container">
      <v-date-picker
        :model-value="props.data?.selected?.[0]?.value"
        @update:model-value="setNewValue"
        color="primary"
        :hide-header="true"
        no-title
        scrollable
      ></v-date-picker>
      <div class="text-right pb-3">
        <v-btn density="compact" variant="text" color="primary" @click="showCalendar = false"> Close </v-btn>
      </div>
    </div>
  </v-menu>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";
import moment from "moment";
import TableFilter from "@/types/TableFilter";
import { useDisplay, useTheme } from "vuetify";

const emit = defineEmits(["update"]);
const props = defineProps<{ readonly data: TableFilter }>();

const showCalendar = ref(false);

const breakpoint = useDisplay();
const theme = useTheme();
const btnStyles = computed(() => ({
  "btn-active-mobile": breakpoint.xs.value,
}));

const isThemeDark = computed(() => theme?.global?.current?.value?.dark);

const clearValue = () => {
  props.data.selected = [];
};
const setNewValue = (newDate: Date) => {
  props.data.selected = [{ value: newDate, text: moment(newDate).format("DD.MM.YYYY") }];
};

watch(
  () => props.data.selected,
  () => {
    if (props.data.selected[0]?.value && showCalendar) showCalendar.value = false;
  },
  { immediate: true }
);
</script>

<style scoped>
.calendar-container {
  background-color: #212121;
}

.btn-active-mobile {
  margin-left: 0 !important;
  margin-right: 0 !important;
  width: 100% !important;
}

.btn {
  margin: 5px;
  text-transform: none;
}

</style>
