<template>
  <div
    v-bind:name="props.name"
    v-bind:desc="props.desc"
    v-bind:valign="props.valign"
    class="d-block d-sm-flex property-editor"
    :class="[valignClass, titleSizeClass]"
  >
    <div class="property-editor-header">
      <div v-if="!props.tooltip" class="text-subtitle-1">{{ props.name }}</div>

      <v-tooltip v-if="props.tooltip" :text="props.tooltip" open-on-click>
        <template v-slot:activator="{ props: tProps }">
          <span v-bind="tProps" class="text-subtitle-1">
            <span>{{ props.name }}</span>
            <sup> <v-icon size="20" class="ml-2">mdi-information-variant-circle-outline</v-icon></sup>
          </span>
        </template>
      </v-tooltip>

      <div v-if="desc" class="desc text-caption text-medium-emphasis text--secondary font-weight-light">
        {{ props.desc }}
      </div>
    </div>
    <div class="property-editor-content flex-grow-1">
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

const props = withDefaults(
  defineProps<{
    readonly name?: string;
    readonly desc?: string;
    readonly valign?: string;
    readonly titleSize?: "default" | "large";
    readonly tooltip?: string;
  }>(),
  {
    name: "",
    desc: "",
    valign: "middle",
    titleSize: "default",
    tooltip: "",
  }
);

const valignClass = computed(() => {
  if (props.valign === "top") {
    return "align-top";
  } else if (props.valign === "middle") {
    return "align-center";
  } else if (props.valign === "bottom") {
    return "align-bottom";
  }
});

const titleSizeClass = computed(() => {
  if (props.titleSize === "large") {
    return "large-title";
  }
  return null;
});
</script>
