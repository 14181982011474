import ResourceBase from "@/resources/ResourceBase";
import RepairItem from "@/types/RepairItem";
import PagedData from "@/types/PagedData";
import Repair from "@/types/Repair";
import RepairTemplate from "@/types/RepairTemplate";
import RepairWorkType from "@/types/RepairWorkType";
import RepairHistory from "@/types/RepairHistory";
import { RepairStatus } from "@/types/RepairStatus";
import { RepairResult } from "@/types/RepairResult";
import { AxiosResponse, AxiosRequestConfig } from "axios";
import GenericResponse from "@/types/GenericResponse";
import Reporter from "@/types/Reporter";

class RepairResource extends ResourceBase {
    getRepairs(deviceId: number, cancelToken: any | undefined = undefined): Promise<AxiosResponse<PagedData<RepairItem>>> {
        const config = {
            params: { itemsPerPage: 100, pageNumber: 1, deviceId },
            cancelToken: cancelToken?.token,
        } as AxiosRequestConfig;

        return this.apiClient.get<PagedData<RepairItem>>("/repairs", config);
    }

    addRepair(repair: Partial<Repair>, cancelToken?: any): Promise<AxiosResponse<Repair>> {
        const config = { cancelToken: cancelToken?.token } as AxiosRequestConfig;

        return this.apiClient.post<Repair>("/repairs", repair, config);
    }

    updateRepair(repair: Repair, cancelToken?: any): Promise<AxiosResponse<Repair>> {
        const config = {
            cancelToken: cancelToken?.token,
        } as AxiosRequestConfig;

        return this.apiClient.put<Repair>(`/repairs/${repair.repairId}`, repair, config);
    }

    deleteRepair(repairId: number, cancelToken?: any): Promise<AxiosResponse<GenericResponse>> {
        const config = { cancelToken: cancelToken?.token } as AxiosRequestConfig;

        return this.apiClient.delete<GenericResponse>(`/repairs/${repairId}`, config);
    }

    getRepairById(repairId: number, cancelToken?: any): Promise<AxiosResponse<Repair>> {
        const config = { cancelToken: cancelToken?.token } as AxiosRequestConfig;
        return this.apiClient.get<Repair>(`/repairs/${repairId}`, config);
    }

    getRepairTemplates(cancelToken?: any): Promise<AxiosResponse<RepairTemplate[]>> {
        const config = { cancelToken: cancelToken?.token } as AxiosRequestConfig;
        return this.apiClient.get<RepairTemplate[]>(`/repairs/templates`, config);
    }

    getRepairWorkTypes(cancelToken?: any): Promise<AxiosResponse<RepairWorkType[]>> {
        const config = { cancelToken: cancelToken?.token } as AxiosRequestConfig;
        return this.apiClient.get<RepairWorkType[]>(`/repairs/work-types`, config);
    }

    getRepairHistory(repairId: number, cancelToken?: any): Promise<AxiosResponse<RepairHistory[]>> {
        const config = { cancelToken: cancelToken?.token } as AxiosRequestConfig;
        return this.apiClient.get<RepairHistory[]>(`/repairs/${repairId}/history`, config);
    }

    deleteApiKey(apiKeyId: number, cancelToken?: any): Promise<AxiosResponse<GenericResponse>> {
        const config = { cancelToken: cancelToken?.token } as AxiosRequestConfig;
        return this.apiClient.delete<GenericResponse>(`/api-keys/${apiKeyId}`, config);
    }

    deleteRepairWorkType(repairWorkTypeId: number, cancelToken?: any): Promise<AxiosResponse<GenericResponse>> {
        const config = { cancelToken: cancelToken?.token } as AxiosRequestConfig;
        return this.apiClient.delete<GenericResponse>(`/repairs/work-types/${repairWorkTypeId}`, config);
    }

    updateRepairWorkType(repairWorkType: RepairWorkType, cancelToken?: any): Promise<AxiosResponse<GenericResponse>> {
        const config = { cancelToken: cancelToken?.token } as AxiosRequestConfig;
        return this.apiClient.put<GenericResponse>(
            `/repairs/work-types/${repairWorkType.repairWorkTypeId}`,
            repairWorkType,
            config
        );
    }

    addRepairWorkType(repairWorkType: Partial<RepairWorkType>, cancelToken?: any): Promise<AxiosResponse<RepairWorkType>> {
        const config = { cancelToken: cancelToken?.token } as AxiosRequestConfig;
        return this.apiClient.post<RepairWorkType>("/repairs/work-types", repairWorkType, config);
    }

    setWorkTypesDisplayOrder(workTypeIdOrder: number[], cancelToken?: any): Promise<AxiosResponse<GenericResponse>> {
        const config = { cancelToken: cancelToken?.token } as AxiosRequestConfig;
        return this.apiClient.post<string>("/repairs/work-types/display-order", workTypeIdOrder, config);
    }

    setRepairTemplateOrder(repairTemplateIdOrder: number[], cancelToken?: any): Promise<AxiosResponse<GenericResponse>> {
        const config = { cancelToken: cancelToken?.token } as AxiosRequestConfig;
        return this.apiClient.post<string>("/repairs/templates/display-order", repairTemplateIdOrder, config);
    }

    addRepairTemplate(
        repairTemplate: {
            repairTemplateId: number;
            name: string;
            description: string;
            issueIds: number[];
            repairWorkTypeIds: number[];
        },
        cancelToken?: any
    ): Promise<AxiosResponse<string>> {
        const config = { cancelToken: cancelToken?.token } as AxiosRequestConfig;
        return this.apiClient.post<string>("/repairs/templates", repairTemplate, config);
    }

    updateRepairTemplate(
        repairTemplate: {
            repairTemplateId: number;
            name: string;
            description: string;
            issueIds: number[];
            repairWorkTypeIds: number[];
        },
        cancelToken?: any
    ): Promise<AxiosResponse<GenericResponse>> {
        const config = { cancelToken: cancelToken?.token } as AxiosRequestConfig;
        return this.apiClient.put<GenericResponse>(
            `/repairs/templates/${repairTemplate.repairTemplateId}`,
            repairTemplate,
            config
        );
    }

    deleteRepairTemplate(repairTemplateId: number, cancelToken?: any): Promise<AxiosResponse<GenericResponse>> {
        const config = { cancelToken: cancelToken?.token } as AxiosRequestConfig;
        return this.apiClient.delete<GenericResponse>(`/repairs/templates/${repairTemplateId}`, config);
    }

    getRepairPaged(
        itemsPerPage: number,
        pageNumber: number,
        searchTerm: string | undefined,
        orderBy: string | undefined = undefined,
        orderDesc: boolean = false,
        location?: string[],
        result?: RepairResult,
        status?: RepairStatus[],
        createdById?: number[],
        from?: Date,
        to?: Date,
        cancelToken?: any
    ): Promise<AxiosResponse<PagedData<Repair>>> {
        const config = {
            params: {
                itemsPerPage,
                pageNumber,
                search: searchTerm,
                orderBy,
                orderDesc,
                location,
                result,
                status,
                createdById,
                from,
                to,
            },
            cancelToken: cancelToken?.token,
        } as AxiosRequestConfig;

        return this.apiClient.get<PagedData<Repair>>("/repairs", config);
    }

    getRepairUsers(search?: string, limit: number = 50, cancelToken?: any): Promise<AxiosResponse<Reporter[]>> {
        const config = {
            params: { search, limit },
            cancelToken: cancelToken?.token,
        } as AxiosRequestConfig;

        return this.apiClient.get<Reporter[]>("/repairs/users", config);
    }
}

const repairResource = new RepairResource();
export default repairResource;
