<template>
  <div>
    <side-sheet
      v-if="props.modelValue"
      :modelValue="showDialog"
      @update:modelValue="close"
      @click-outside="close"
      :heading="dialogHeading"
      :noClickAnimation="true"
    >
      <template v-slot:tabs>
        <v-tabs v-model="tab" grow color="primary" slider-color="primary">
          <v-tab value="poi">POI</v-tab>
          <v-tab value="history">History</v-tab>
        </v-tabs>
      </template>

      <v-window class="full-height-tabs-wrap tabs-w-100" v-model="tab" :touch="false">
        <!-- POI -->
        <v-tabs-window-item :reverse-transition="false" value="poi" transition="none">
          <v-form ref="poiForm" v-model="valid" lazy-validation>
            <PropEditor v-if="props.modelValue.poiId" name="Info">
              <div class="text-subtitle-2">
                <div><span class="info-label">POI ID:</span> {{ props.modelValue?.poiId }}</div>
                <div v-if="props.modelValue.createdDate">
                  <span class="info-label">Created:</span> {{ moment(props.modelValue?.createdDate).format("lll") }}
                </div>
                <div v-if="props.modelValue.updatedDate && props.modelValue.createdDate !== props.modelValue.updatedDate">
                  <span class="info-label">Updated:</span> {{ moment(props.modelValue?.updatedDate).format("lll") }}
                </div>
                <div v-if="props.modelValue.timeToLive">
                  <span class="info-label">TTL:</span> {{ moment(props.modelValue?.timeToLive).format("lll") }}
                </div>
                <div><span class="info-label">Negative reports:</span> {{ props.modelValue?.numberOfNegativeReports }}</div>
                <div v-if="props.modelValue.nvdbObjectId">
                  <span class="info-label">NVDB ID:</span> {{ props.modelValue?.nvdbObjectId }}
                </div>
                <div><span class="info-label">Geohash:</span> {{ props.modelValue?.geohash }}</div>
              </div>
            </PropEditor>
            <PropEditor name="Activate POI" desc="">
              <v-switch v-model="props.modelValue.isActive" density="compact" :readonly="!canEditPoi" class="my-0" />
            </PropEditor>
            <PropEditor
              v-if="!props.modelValue.isActive"
              name="Reactivation lock"
              desc="Restricts devices from reactivating or creating any control-type POI within a 1.5 km radius of this POI."
            >
              <ReactivationLockout :modelValue="props.modelValue" :canEditPoi="canEditPoi" />
            </PropEditor>

            <PropEditor v-if="props.modelValue.isActive && showTtl" name="TTL" desc="Time To Live">
              <v-row class="align-center">
                <v-col>
                  <v-slider
                    min="0"
                    :max="ttlMax"
                    v-model="ttl"
                    step="1"
                    hide-details
                    :readonly="!canEditPoi"
                    class="my-0"
                  ></v-slider>
                </v-col>
                <v-col class="flex-grow-0">
                  <v-text-field
                    v-model="ttl"
                    type="number"
                    step="1"
                    min="0"
                    :max="ttlMax"
                    variant="outlined"
                    :readonly="!canEditPoi"
                    density="compact"
                    style="width: 80px"
                    label="Minutes"
                  />
                </v-col>
              </v-row>
            </PropEditor>
            <PropEditor name="Location" valign="top">
              <v-autocomplete
                v-model="geocodeSelectedItem"
                :items="geocodeItems"
                :loading="geocodeLoading"
                v-model:search="geocodeSearchTerm"
                density="compact"
                variant="outlined"
                clearable
                hide-no-data
                no-filter
                label="Address search"
                item-title="formatted_address"
                class="mb-2 map-search-field"
                :loader-height="4"
                return-object
              ></v-autocomplete>
              <div class="map-wrap">
                <l-map
                  v-if="showMap && props.modelValue"
                  class="map-container"
                  :zoom.sync="mapZoom"
                  :center="mapCenter"
                  @click="setMarker"
                  @contextmenu="() => false"
                  :noBlockingAnimations="true"
                >
                  <l-tile-layer :url="leaflet.url" :attribution="leaflet.attribution"></l-tile-layer>
                  <l-control-scale position="bottomleft" :imperial="false" :metric="true"></l-control-scale>
                  <!-- ACTIVE MARKER -->
                  <l-marker-rotate
                    v-if="mapIcon"
                    :lat-lng="[props.modelValue.latitude, props.modelValue.longitude]"
                    :draggable="canEditPoi"
                    ref="rotateMarker"
                    :rotationAngle="mapIcon.roatationOrigin ? props.modelValue.direction : 0"
                    :rotationOrigin="mapIcon.roatationOrigin ? mapIcon.roatationOrigin : null"
                    @dragend="markerPositionUpdated"
                    @click="
                      () => {
                        return false;
                      }
                    "
                  >
                    <l-icon
                      v-if="!minAllowedDistanceWarning"
                      :icon-url="mapIcon.iconUrl"
                      :icon-size="mapIcon.iconSize"
                      :icon-anchor="mapIcon.iconAnchor"
                      :className="'poi ' + getPoiMarkerClass(props.modelValue)"
                      :tooltipAnchor="[200000, -200000]"
                    ></l-icon>
                    <l-icon
                      v-else
                      :icon-url="mapIconError.iconUrl"
                      :icon-size="mapIconError.iconSize"
                      :icon-anchor="mapIconError.iconAnchor"
                      :className="'poi poi-active'"
                      :tooltipAnchor="[20, -20]"
                    ></l-icon>
                    <l-tooltip v-if="minAllowedDistanceWarning">
                      <div class="poi-tooltip text-red">
                        <h4 v-show="minAllowedDistanceWarning_newPoi">
                          Too close to an existing POI.<br />Consider reactivating nearest POI.
                        </h4>
                        <h4 v-show="minAllowedDistanceWarning_updatePoi">Too close to an existing active POI.</h4>
                      </div>
                    </l-tooltip>
                    <l-tooltip v-else>
                      {{ props.modelValue?.poiId ? "Currently editing POI" : "New POI" }}
                    </l-tooltip>
                  </l-marker-rotate>
                  <!-- NEARBY MARKERS -->
                  <l-marker-rotate
                    v-for="(poiItem, ind) in nearbyPoi"
                    :key="ind"
                    :lat-lng="[poiItem[1].poi.latitude, poiItem[1].poi.longitude]"
                    :rotationAngle="poiItem[1].icon.roatationOrigin ? poiItem[1].poi.direction : 0"
                    :rotationOrigin="poiItem[1].icon.roatationOrigin ? poiItem[1].icon.roatationOrigin : null"
                    @click="(e:any) => e.originalEvent.stopPropagation()"
                  >
                    <l-icon
                      :icon-url="poiItem[1].icon.iconUrl"
                      :icon-size="poiItem[1].icon.iconSize"
                      :icon-anchor="poiItem[1].icon.iconAnchor"
                      :tooltipAnchor="[20, -20]"
                      :popupAnchor="[0, -40]"
                      :className="'poi-nearby ' + getPoiMarkerClass(poiItem[1].poi)"
                    ></l-icon>
                    <l-tooltip>
                      <div class="poi-tooltip">
                        <h4>{{ getPoiTypeName(poiItem[1].poi.type) }} ID: {{ poiItem[1].poi.poiId }}</h4>
                        <div>Distance: {{ poiItem[1].distance.toFixed(0) }} m</div>
                      </div>
                    </l-tooltip>
                    <l-popup>
                      <div class="poi-popup">
                        <h4>{{ getPoiTypeName(poiItem[1].poi.type) }} ID: {{ poiItem[1].poi.poiId }}</h4>
                        <h4 class="no-wrap font-weight-regular mb-1">{{ poiItem[1].poi.areaInfo }}</h4>
                        <div v-if="isSpeedCameraType(poiItem[1].poi.type)">
                          <div>Speed limit: {{ poiItem[1].poi.speedLimit }}</div>
                        </div>
                        <div v-else-if="isCameraType(poiItem[1].poi.type)"></div>
                        <div v-else>
                          <div>Active: {{ poiItem[1].poi.isActive }}</div>
                          <div>TTL: {{ moment(poiItem[1].poi.timeToLive).format("lll") }}</div>
                        </div>
                        <div class="text-blue my-0 mt-1 cursor-pointer" @click="emit('edit-another-poi', poiItem[1].poi)">
                          <b>Edit (close current POI)</b>
                        </div>
                      </div>
                    </l-popup>
                  </l-marker-rotate>
                </l-map>
              </div>
              <v-row class="mt-2">
                <v-col>
                  <v-row align="center" class="ma-0">
                    <v-text-field
                      v-model="latLng"
                      label="POI coordinates (latitude, longitude)"
                      density="compact"
                      variant="outlined"
                      :flat="!canEditPoi"
                      :readonly="!canEditPoi"
                      clearable
                      @update:model-value="() => applyLatLngToPoi()"
                      :rules="latLngRule"
                      :disabled="locationLoading"
                    />

                    <v-btn
                      v-if="isMyLocBtn && canEditPoi"
                      color="primary"
                      class="ml-2"
                      density="comfortable"
                      @click="setMyLocation"
                      :loading="locationLoading"
                      :disabled="locationLoading"
                      >My Loc
                    </v-btn>
                  </v-row>
                </v-col>
              </v-row>
            </PropEditor>
            <PropEditor name="POI type">
              <v-select
                attach
                v-model="props.modelValue.type"
                :items="poiTypes"
                density="compact"
                single-line
                :variant="canEditPoi ? 'outlined' : 'solo'"
                :flat="!canEditPoi"
                :readonly="!canEditPoi"
              ></v-select>
            </PropEditor>
            <PropEditor v-if="showDirection" name="Heading">
              <v-row class="align-center">
                <v-col>
                  <v-slider
                    min="0"
                    max="359"
                    v-model="props.modelValue.direction"
                    step="1"
                    hide-details
                    :readonly="!canEditPoi"
                    class="my-0"
                  ></v-slider>
                </v-col>
                <v-col class="flex-grow-0">
                  <v-text-field
                    v-model="props.modelValue.direction"
                    type="number"
                    variant="outlined"
                    density="compact"
                    :readonly="!canEditPoi"
                    style="width: 80px"
                    label="Degrees"
                  />
                </v-col>
              </v-row>
            </PropEditor>
            <PropEditor v-if="showSpeedLimit" name="Speed limit">
              <v-row class="align-center">
                <v-col>
                  <v-slider
                    min="0"
                    max="120"
                    v-model="props.modelValue.speedLimit"
                    step="5"
                    hide-details
                    :readonly="!canEditPoi"
                    class="my-0"
                  ></v-slider>
                </v-col>
                <v-col class="flex-grow-0">
                  <v-text-field
                    v-model="props.modelValue.speedLimit"
                    type="number"
                    variant="outlined"
                    density="compact"
                    step="5"
                    min="0"
                    max="120"
                    :readonly="!canEditPoi"
                    style="width: 80px"
                    label="Km/h"
                  />
                </v-col>
              </v-row>
            </PropEditor>
            <PropEditor name="Area info" desc="Text that will be displayed on the device screen">
              <AreaInfoEditor v-model="props.modelValue.areaInfo" :readonly="!canEditPoi" />
              <v-row class="mt-2 align-center" v-if="canEditPoi">
                <v-col cols="12" md="8">
                  <v-text-field
                    v-model="suggestedAreaInfo"
                    density="compact"
                    variant="outlined"
                    disabled
                    readonly
                    label="Suggested area info"
                  />
                </v-col>
                <v-col cols="12" md="4" class="flex-grow-0 pt-0 pt-md-2 text-center">
                  <v-btn size="small" width="100%" color="secondary" @click="applySuggestedAreaInfo()"
                    >Apply suggested</v-btn
                  >
                </v-col>
              </v-row>
            </PropEditor>
            <PropEditor name="Search tags" desc="">
              <v-text-field
                v-model="props.modelValue.searchTags"
                density="compact"
                :variant="canEditPoi ? 'outlined' : 'solo'"
                :flat="!canEditPoi"
                :readonly="!canEditPoi"
              ></v-text-field>
            </PropEditor>
            <PropEditor name="Warning radius">
              <div class="d-flex mb-2">
                <v-text-field
                  v-model="warningRange[0]"
                  class="flex-grow-0"
                  style="width: 140px"
                  type="number"
                  step="50"
                  label="Short range, meters"
                  variant="outlined"
                  density="compact"
                  :readonly="!canEditPoi"
                  @change="(event: Event) => (warningRange[0] = Number((event.target as HTMLInputElement).value))"
                ></v-text-field>
                <v-spacer />
                <v-text-field
                  v-model="warningRange[1]"
                  class="flex-grow-0"
                  style="width: 140px"
                  type="number"
                  step="50"
                  label="Long range, meters"
                  variant="outlined"
                  density="compact"
                  :readonly="!canEditPoi"
                  @change="(event: Event) => (warningRange[1] = Number((event.target as HTMLInputElement).value))"
                ></v-text-field>
              </div>
              <v-range-slider
                min="300"
                max="5000"
                v-model="warningRange"
                :readonly="!canEditPoi"
                step="50"
                hide-details
              ></v-range-slider>
            </PropEditor>
          </v-form>
        </v-tabs-window-item>

        <!-- HYSTORY -->
        <v-tabs-window-item :reverse-transition="false" value="history" transition="none">
          <PoiChangeHistory :poiId="changeHistoryPoiId" />
        </v-tabs-window-item>
      </v-window>

      <template v-slot:actions>
        <v-btn
          v-if="canDeletePoi && props.modelValue.poiId"
          color="secondary"
          @click="deletePoiConfirm"
          :loading="deleting"
          :disabled="deleting"
          >Delete</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn variant="text" @click="showDialog = false">Cancel</v-btn>
        <v-btn
          v-if="canEditPoi"
          color="primary"
          class="ml-4"
          @click="submit"
          :loading="loading"
          :disabled="Boolean(loading || disabledSubmitBtn)"
          >Submit</v-btn
        >
      </template>
    </side-sheet>
  </div>
</template>

<script setup lang="ts">
import SideSheet from "@/components/layout/SideSheet.vue";
import PropEditor from "@/components/layout/PropEditor.vue";
import ReactivationLockout from "@/components/poi/ReactivationLockout.vue";
import infoMessageService from "@/services/InfoMessageService";
import { InfoMessageType } from "@/types/InfoMessageType";
import userProfileService from "@/services/UserProfileService";
import moment from "moment";
import { UserPermissionType } from "@/types/UserPermissionType";
import Poi from "@/types/Poi";
import PoiHelper from "@/helpers/poiHelper";
import poiResource from "@/resources/PoiResource";
import { PoiType } from "@/types/PoiType";
import L from "leaflet";
import { LMap, LTileLayer, LControlScale, LIcon, LTooltip, LPopup } from "@vue-leaflet/vue-leaflet";
//@ts-ignore
import { LMarkerRotate } from "vue-leaflet-rotate-marker";
import MapIcon from "@/types/MapIcon";
import MapHelper from "@/helpers/mapHelper";
import AreaInfoEditor from "@/components/poi/AreaInfoEditor.vue";
import googleMapsResource from "@/resources/GoogleMapsResource";
import appConfigService from "@/services/AppConfigService";
import MapIcons from "@/types/MapIcons";
import PoiChangeHistory from "@/components/poi/PoiChangeHistory.vue";
import ChangeManager from "@/services/ChangeManager";
import { VForm } from "vuetify/components";
import { ref, computed, watch, nextTick } from "vue";
import { useComponentQuery } from "@/globalProperties";
import { useConfirm } from "@/services/ConfirmService";

interface PoiMarker {
  poi: Poi;
  icon: MapIcon;
  distance: number;
}

const emit = defineEmits(["update:modelValue", "updated", "edit-another-poi"]);
const props = withDefaults(
  defineProps<{
    readonly modelValue?: Poi | null;
    readonly poiInitTab: string | null;
  }>(),
  {
    modelValue: null,
    poiInitTab: null,
  }
);

const { setComponentQuery } = useComponentQuery();
const confirm = useConfirm();

const disabledSubmitBtn = computed(() => props.modelValue?.poiId && !ChangeManager.state().isChanged);

const changeHistoryPoiId = ref<number | null>(null);

const tab = ref<string | null>(null);

watch(tab, function onTabChange(val: string | null) {
  if (val === "history") {
    changeHistoryPoiId.value = props.modelValue!.poiId;
  }
  if (props.modelValue?.poiId) {
    setComponentQuery("poiTab", val);
  }
});

const showDialog = computed({
  get() {
    return props.modelValue != null;
  },
  set(value: boolean) {
    emit("update:modelValue", null);
  },
});

const dialogHeading = computed(() => {
  let heading = "";
  if (props.modelValue) {
    heading = props.modelValue?.poiId ? `POI ID: ${props.modelValue.poiId}` : "New POI";
  }
  return heading;
});

const valid = ref(true);
const loading = ref(false);

const poiTypes = [
  { title: PoiHelper.getPoiTypeDisplayName(PoiType.Checkpoint), value: PoiType.Checkpoint },
  { title: PoiHelper.getPoiTypeDisplayName(PoiType.SpeedControl), value: PoiType.SpeedControl },
  { title: PoiHelper.getPoiTypeDisplayName(PoiType.SeatBeltControl), value: PoiType.SeatBeltControl },
  { title: PoiHelper.getPoiTypeDisplayName(PoiType.TechnicalControl), value: PoiType.TechnicalControl },
  { title: PoiHelper.getPoiTypeDisplayName(PoiType.CustomsControl), value: PoiType.CustomsControl },
  { title: PoiHelper.getPoiTypeDisplayName(PoiType.RoadCondition), value: PoiType.RoadCondition },
  { title: PoiHelper.getPoiTypeDisplayName(PoiType.Animal), value: PoiType.Animal },
  { title: PoiHelper.getPoiTypeDisplayName(PoiType.SpeedCamera), value: PoiType.SpeedCamera },
  { title: PoiHelper.getPoiTypeDisplayName(PoiType.AverageCamera), value: PoiType.AverageCamera },
  { title: PoiHelper.getPoiTypeDisplayName(PoiType.AverageCameraEnd), value: PoiType.AverageCameraEnd },
];

const ttl = ref(0);
const ttlMax = ref(120);
const initialIsActive = ref(false);

const warningRange = ref([0, 0]);

const showSpeedLimit = computed(
  () =>
    props.modelValue && (props.modelValue.type === PoiType.SpeedCamera || props.modelValue.type === PoiType.AverageCamera)
);

const showDirection = computed(() => {
  return (
    props.modelValue &&
    (props.modelValue.type === PoiType.SpeedCamera ||
      props.modelValue.type === PoiType.AverageCamera ||
      props.modelValue.type === PoiType.AverageCameraEnd)
  );
});

const showTtl = computed(() => {
  return (
    props.modelValue &&
    (props.modelValue.type === PoiType.Checkpoint ||
      props.modelValue.type === PoiType.RoadCondition ||
      props.modelValue.type === PoiType.SpeedControl ||
      props.modelValue.type === PoiType.SeatBeltControl ||
      props.modelValue.type === PoiType.TechnicalControl ||
      props.modelValue.type === PoiType.CustomsControl ||
      props.modelValue.type === PoiType.Animal)
  );
});

const showMap = ref(false);
const mapCenter = ref([0, 0]);
const mapZoom = ref(15);
const leaflet = {
  url: MapHelper.defaultMapTilesUrl,
  attribution: MapHelper.defaultMapAttr,
};

const mapIcon = ref<MapIcon | undefined>();
const mapIconError = MapIcons.error;
const minAllowedDistanceWarning_newPoi = ref(false);
const minAllowedDistanceWarning_updatePoi = ref(false);

const minAllowedDistanceWarning = computed(
  () => minAllowedDistanceWarning_newPoi.value || minAllowedDistanceWarning_updatePoi.value
);

const setMarker = (e: any) => {
  if (canEditPoi.value && props.modelValue) {
    latLng.value = `${e.latlng.lat.toFixed(6)}, ${e.latlng.lng.toFixed(6)}`;
    applyLatLngToPoi(false);
  }
};

const markerPositionUpdated = (e: any) => {
  if (canEditPoi.value && props.modelValue) {
    const latlng = e.target.getLatLng();
    latLng.value = `${latlng.lat.toFixed(6)}, ${latlng.lng.toFixed(6)}`;
    applyLatLngToPoi(false);
  }
};

const latLng = ref<string | undefined>("");
const latLngRule = [
  (v: string) => !!v || "Field is required",
  (v: string) =>
    /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/.test(v) ||
    "Correct format is 'lat, lng', ex. 63.123456, 10.123456",
];

const poiForm = ref<InstanceType<typeof VForm> | null>(null);
const setLatLngFromPoi = () => {
  latLng.value = `${props.modelValue!.latitude.toFixed(6)}, ${props.modelValue!.longitude.toFixed(6)}`;
};

const applyLatLngToPoi = async (recenterMap: boolean = true) => {
  const { valid } = (await poiForm.value?.validate()) || {};

  if (!latLng.value || latLng.value === undefined || !valid) {
    return;
  }

  infoMessageService.clear();

  let latLngArray = latLng.value.split(",").map((elm) => parseFloat(elm.trim()));
  if (latLngArray.length === 2 && !latLngArray.some((elm) => isNaN(elm))) {
    props.modelValue!.latitude = parseFloat(latLngArray[0].toFixed(6));
    props.modelValue!.longitude = parseFloat(latLngArray[1].toFixed(6));

    if (recenterMap) {
      mapCenter.value = latLngArray;
    }
    getNearbyPoi();
    getAreaInfoSuggestion();
  } else {
    infoMessageService.show(InfoMessageType.Error, "Cannot parse latitude, longitude value.");
  }
};

const nearbyPoiLastLocation = ref<{ lat: number; lng: number }>({ lat: 0, lng: 0 });
const nearbyPoi = ref<Map<number, PoiMarker>>(new Map<number, PoiMarker>());

const canAddPoi = computed(() => userProfileService.hasPermission(UserPermissionType.AddPoi));
const canEditPoi = computed(() => {
  return (
    userProfileService.hasPermission(UserPermissionType.EditPoi) ||
    (props.modelValue && props.modelValue.poiId === 0 && canAddPoi.value)
  );
});

const canDeletePoi = computed(() => userProfileService.hasPermission(UserPermissionType.DeletePoi));

const deleting = ref(false);

const locationLoading = ref(true);
const getPoiTypeName = (type: PoiType) => {
  return PoiHelper.getPoiTypeDisplayName(type);
};

const isCameraType = (type: PoiType) => {
  return type === PoiType.SpeedCamera || type === PoiType.AverageCamera || type === PoiType.AverageCameraEnd;
};

const isSpeedCameraType = (type: PoiType) => {
  return type === PoiType.SpeedCamera;
};

const getNearbyPoi = () => {
  if (!props.modelValue) {
    return;
  }

  // Check if location has significantly changed
  if (
    measureDistanceToCurrentPoi(nearbyPoiLastLocation.value.lat, nearbyPoiLastLocation.value.lng) >=
    PoiHelper.DefaultSearchRadius / 2
  ) {
    nearbyPoiLastLocation.value.lat = props.modelValue.latitude;
    nearbyPoiLastLocation.value.lng = props.modelValue.longitude;

    // POIs to fetch bounding box
    const offMeters = PoiHelper.DefaultSearchRadius;
    const ne = MapHelper.offsetMeters(props.modelValue.latitude, props.modelValue.longitude, offMeters, offMeters);
    const sw = MapHelper.offsetMeters(props.modelValue.latitude, props.modelValue.longitude, -offMeters, -offMeters);

    // Fetch
    poiResource
      .getPoiInBounds(ne.lat, ne.lon, sw.lat, sw.lon, undefined, undefined, undefined, true)
      .then((resp) => {
        var newNearbyPoi = new Map(nearbyPoi.value);
        resp.data.forEach((item) => {
          // check not exists or not current poi
          if (!nearbyPoi.value.has(item.poiId) && props.modelValue && props.modelValue.poiId !== item.poiId) {
            newNearbyPoi.set(item.poiId, {
              poi: item as Poi,
              icon: PoiHelper.getMapIconByType(item.type),
              distance: measureDistanceToCurrentPoi(item.latitude, item.longitude),
            });
          }
        });

        // apply new Map
        nearbyPoi.value = newNearbyPoi;

        // check if any poi in min allowed proximity distance
        updateMinAllowedDistanceWarning();
      })
      .catch(poiResource.defaultErrorHandler);
  }

  // set distance to current poi
  setDistanceToPoi(nearbyPoi.value);

  // check if any poi in min allowed proximity distance
  updateMinAllowedDistanceWarning();
};

const resetNearbyPoi = () => {
  nearbyPoiLastLocation.value = { lat: 0, lng: 0 };
  nearbyPoi.value.clear();
};

const getPoiMarkerClass = (poi: Poi) => {
  return PoiHelper.getPoiIconClassName(poi);
};

const setDistanceToPoi = (poiMarkersMap: Map<number, PoiMarker>) => {
  poiMarkersMap.forEach((poiMarker) => {
    poiMarker.distance = measureDistanceToCurrentPoi(poiMarker.poi.latitude, poiMarker.poi.longitude);
  });
};

const measureDistanceToCurrentPoi = (lat: number, lng: number) => {
  return PoiHelper.measureDistance(props.modelValue!.latitude, props.modelValue!.longitude, lat, lng);
};

const updateMinAllowedDistanceWarning = () => {
  minAllowedDistanceWarning_newPoi.value = false;
  minAllowedDistanceWarning_updatePoi.value = false;

  // Check if any poi in min allowed proximity distance (for new and dinamyc poi only)
  if (props.modelValue!.poiId === 0 && !PoiHelper.isPermanentPoiType(props.modelValue!.type)) {
    nearbyPoi.value.forEach((poiMarker) => {
      const isPermPoi = PoiHelper.isPermanentPoiType(poiMarker.poi.type);
      if (
        (isPermPoi && poiMarker.distance < appConfigService.newPoiDynamicToStaticPoIMinAllowedProximityDistance) ||
        (!isPermPoi && poiMarker.distance < appConfigService.newPoiDynamicPoiMinAllowedProximityDistance)
      ) {
        minAllowedDistanceWarning_newPoi.value = true;
      }
    });
    // Check if any active dynamic poi in min allowed proximity distance (when updating existing and dinamyc poi only)
  } else if (props.modelValue!.poiId !== 0 && !PoiHelper.isPermanentPoiType(props.modelValue!.type)) {
    nearbyPoi.value.forEach((poiMarker) => {
      const isPermPoi = PoiHelper.isPermanentPoiType(poiMarker.poi.type);
      if (
        !isPermPoi &&
        poiMarker.poi.isActive &&
        poiMarker.distance < appConfigService.updatePoiDynamicPoiMinAllowedProximityDistance
      ) {
        minAllowedDistanceWarning_updatePoi.value = true;
      }
    });
  }
};

const rotateMarker = ref(null);
const isMyLocBtn = ref(true);

watch(
  () => props.modelValue,
  async function enableActiveMarkerDragging() {
    if (props.modelValue) {
      await nextTick();
      setTimeout(() => {
        //@ts-ignore
        const marker = rotateMarker.value?.leafletObject;
        if (marker) {
          marker.dragging.enable();
        }
      }, 800);
    }
  }
);

watch(
  () => props.modelValue,
  async function geolocationPermission() {
    isMyLocBtn.value = true;
    locationLoading.value = true;

    if (props.modelValue) {
      if ("permissions" in navigator) {
        try {
          const permissionStatus = await navigator.permissions.query({ name: "geolocation" });
          if (permissionStatus.state !== "granted" && permissionStatus.state !== "prompt") {
            isMyLocBtn.value = false;
          }
        } catch (e) {
          isMyLocBtn.value = false;
        }
      } else {
        isMyLocBtn.value = false;
      }
    }

    locationLoading.value = false;
  }
);

const setMyLocation = async () => {
  if ("geolocation" in navigator) {
    locationLoading.value = true;
    let position: GeolocationPosition | null = null;

    try {
      position = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      });

      const latitude = Number(position?.coords?.latitude?.toFixed(6));
      const longitude = Number(position?.coords?.longitude?.toFixed(6));

      if (latitude && longitude && canEditPoi.value && props.modelValue) {
        latLng.value = `${latitude}, ${longitude}`;
        applyLatLngToPoi(true);
      }
    } catch (error: any) {
      infoMessageService.show(InfoMessageType.Error, error.message || "Error while getting geolocation");

      navigator.permissions
        .query({ name: "geolocation" })
        .then((permissionStatus) => {
          if (permissionStatus.state !== "granted" && permissionStatus.state !== "prompt") {
            isMyLocBtn.value = false;
          }
        })
        .catch((e) => {
          isMyLocBtn.value = false;
        });
    }
  } else {
    isMyLocBtn.value = false;
    infoMessageService.show(InfoMessageType.Error, "Geolocation is not supported by this browser.");
  }

  locationLoading.value = false;
};
const resetMinAllowedDistanceWarning = () => {
  minAllowedDistanceWarning_newPoi.value = false;
  minAllowedDistanceWarning_updatePoi.value = false;
};

const geocodeSearchTerm = ref("");
const geocodeLoading = ref(false);
const geocodeSelectedItem = ref<google.maps.GeocoderResult | null>(null);
const geocodeItems = ref<google.maps.GeocoderResult[]>([]);
let geocodeTimeout = 0;

watch(geocodeSearchTerm, function onGeocodeSearch(term: string) {
  // Search threshold
  if (geocodeTimeout) {
    clearTimeout(geocodeTimeout);
    geocodeTimeout = 0;
  }

  if (!term) {
    geocodeSelectedItem.value = null;
    geocodeItems.value = [];
    geocodeLoading.value = false;
    return;
  }

  if (geocodeSelectedItem.value != null) return;

  geocodeItems.value = [];
  geocodeLoading.value = true;
  geocodeTimeout = setTimeout(() => {
    geocode();
  }, 2000);
});

watch(geocodeSelectedItem, function onGeocodeItemSelected() {
  if (geocodeSelectedItem.value) {
    mapZoom.value = 15;
    mapCenter.value = [geocodeSelectedItem.value.geometry.location.lat(), geocodeSelectedItem.value.geometry.location.lng()];
  }
});

const geocode = () => {
  geocodeLoading.value = true;
  googleMapsResource
    .geocodeAddress(geocodeSearchTerm.value)
    .then((resp) => {
      if (resp.results) {
        geocodeItems.value = resp.results;
      }
    })
    .catch(googleMapsResource.defaultErrorHandler)
    .finally(() => {
      geocodeLoading.value = false;
    });
};

const suggestedAreaInfo = ref(" ");
const getAreaInfoSuggestion = () => {
  googleMapsResource.reverseGeocodeLocation(props.modelValue!.latitude, props.modelValue!.longitude).then((resp) => {
    if (resp.results && resp.results.length) {
      suggestedAreaInfo.value = PoiHelper.getLocationAreaInfo(resp.results);
    }
  });
};

const applySuggestedAreaInfo = () => {
  if (suggestedAreaInfo.value.trim()) {
    props.modelValue!.areaInfo = suggestedAreaInfo.value.replace(": ", "\n");
  }
};

const submit = async () => {
  if (props.modelValue === null) {
    return;
  }
  // Validate form
  const { valid } = (await poiForm.value?.validate()) || {};

  if (valid) {
    // Get value
    props.modelValue.shortRangeWarningRadius = warningRange.value[0];
    props.modelValue.longRangeWarningRadius = warningRange.value[1];
    if (props.modelValue?.isActive) {
      props.modelValue.timeToLive = moment().add(ttl.value, "m").toDate();
    } else {
      props.modelValue.timeToLive = undefined;
    }

    if (props.modelValue.poiId) {
      // Update
      loading.value = true;
      poiResource
        .updatePoi(props.modelValue)
        .then((resp) => {
          showDialog.value = false;
          emit("updated", props.modelValue);
        })
        .catch((e: Error) => {
          // reload nearby POI on error
          resetNearbyPoi();
          getNearbyPoi();

          poiResource.defaultErrorHandler(e);
        })
        .finally(() => {
          loading.value = false;
        });
    } else {
      // New
      loading.value = true;
      poiResource
        .addPoi(props.modelValue)
        .then((resp) => {
          infoMessageService.show(InfoMessageType.Success, `New POI with ID ${resp.data} created`);
          showDialog.value = false;
          emit("updated", props.modelValue);
        })
        .catch(poiResource.defaultErrorHandler)
        .finally(() => {
          loading.value = false;
        });
    }
  }
};

const deletePoiConfirm = () => {
  if (!canDeletePoi.value || props.modelValue == null) {
    return;
  }

  confirm.show(`Delete POI ID '${props.modelValue.poiId}'?`).then((confirmed) => {
    if (confirmed) {
      deletePoi();
    }
  });
};

const deletePoi = () => {
  if (!canDeletePoi.value || props.modelValue == null) {
    return;
  }

  deleting.value = true;
  poiResource
    .deletePoi(props.modelValue.poiId)
    .then((resp) => {
      showDialog.value = false;
      emit("updated");
    })
    .catch(poiResource.defaultErrorHandler)
    .finally(() => {
      deleting.value = false;
    });
};

const close = (value: boolean) => {
  if (!value && ChangeManager.state().isChanged) {
    ChangeManager.show();
    return;
  }

  showDialog.value = value;
};

// begin change management
const changesControl = ref<ChangeManager | null>(null);
watch(
  () => props.modelValue,
  function setChangeManager(val: Poi | null, oldValue: Poi | null) {
    changesControl.value = ChangeManager.modalController({
      controller: changesControl.value,
      isNewValue: val && oldValue === null,
      isDestroy: oldValue && val === null,
      isUpdateValue: oldValue && val && oldValue.poiId !== val.poiId,
      data: { poi: val, ttl: ttl.value, reactivationLockout: val?.reactivationLockout },
      message: "You have unsaved Poi changes.",
      target: `poi_${val?.poiId}`,
      onLeave: () => {
        showDialog.value = false;
      },
      onSave: submit,
    });
  }
);

watch(
  () => props.modelValue,
  function checkChangesStatus() {
    setChangesStatus();
  },
  { deep: true }
);

watch(
  () => [ttl.value, warningRange.value],
  function onChangeDT() {
    setChangesStatus();
  }
);

const setChangesStatus = () => {
  if (!props.modelValue) {
    return;
  }

  const origPoi = changesControl.value?.data?.origData?.poi;
  const origTtl = changesControl.value?.data?.origData?.ttl;
  const origReactivationLockout = moment(changesControl.value?.data?.origData?.reactivationLockout);

  if (!changesControl.value || !origPoi) return;

  if (Math.round(origTtl) !== Math.round(ttl.value)) {
    changesControl.value?.activate();
    return;
  }

  const lockout = moment(props.modelValue.reactivationLockout);
  if (origReactivationLockout.format("YYYY-MM-DD HH:mm") !== lockout.format("YYYY-MM-DD HH:mm")) {
    changesControl.value?.activate();
    return;
  }

  const origWarningRange = [origPoi.shortRangeWarningRadius, origPoi.longRangeWarningRadius];
  if (JSON.stringify([...warningRange.value]) !== JSON.stringify(origWarningRange)) {
    changesControl.value?.activate();
    return;
  }

  if (
    !ChangeManager.isObjectEqual(origPoi, props.modelValue || {}, { isOrigPartial: true, exclude: ["reactivationLockout"] })
  ) {
    changesControl.value?.activate();
    return;
  }

  changesControl.value?.deactivate();
};
// end change management

watch(
  () => props.modelValue,
  function onPoiChange() {
    if (props.modelValue) {
      ttl.value = PoiHelper.getTtlMinutes(props.modelValue.timeToLive);
      changesControl.value?.addOrigData({ ttl: ttl.value });
      changesControl.value?.addOrigData({ reactivationLockout: props.modelValue.reactivationLockout });
      warningRange.value = [props.modelValue.shortRangeWarningRadius, props.modelValue.longRangeWarningRadius];
      mapCenter.value = [props.modelValue.latitude, props.modelValue.longitude];
      mapZoom.value = 15;
      initialIsActive.value = props.modelValue.isActive;
      setLatLngFromPoi();
      getNearbyPoi();

      // fetch area info suggestion if new poi
      if (!props.modelValue.poiId) {
        getAreaInfoSuggestion();
      }

      // delay map init
      setTimeout(() => {
        showMap.value = true;
      }, 100);

      if (props.poiInitTab) {
        tab.value = props.poiInitTab || null;
      }
    } else {
      setComponentQuery("poiTab", null);
      tab.value = null;
      showMap.value = false;
      mapCenter.value = [0, 0];
      geocodeSelectedItem.value = null;
      geocodeItems.value = [];
      suggestedAreaInfo.value = " ";
      changeHistoryPoiId.value = null;
      resetMinAllowedDistanceWarning();
      resetNearbyPoi();
    }

    setComponentQuery("poiId", props.modelValue?.poiId ? props.modelValue.poiId : null);
  }
);

watch(
  () => props.modelValue?.isActive,
  function onPoiIsActiveChange(isActive: boolean | undefined) {
    // Set TTL to max when reactivating old POI
    if (isActive && initialIsActive.value === false && !ttl.value) {
      ttl.value = ttlMax.value;
    }
    if (!isActive && initialIsActive.value === false) {
      ttl.value = changesControl.value?.data?.origData?.ttl;
    }

    const originReactivationLockout = changesControl.value?.data?.origData?.reactivationLockout;

    if (props.modelValue && isActive) {
      props.modelValue.reactivationLockout = originReactivationLockout ? null : originReactivationLockout;
    }

    if (props.modelValue && !isActive) {
      props.modelValue.reactivationLockout = originReactivationLockout;
    }
  }
);

watch(
  () => props.modelValue?.type,
  function onPoiTypeChange(type: PoiType | undefined) {
    if (props.modelValue && (type || type === 0)) {
      mapIcon.value = PoiHelper.getMapIconByType(type);
      updateMinAllowedDistanceWarning();
    }
  }
);
</script>

<style scoped>
.map-wrap {
  width: 100%;
  height: 360px;
}
</style>
