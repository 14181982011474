<template>
  <div>
    <div class="d-flex justify-space-between">
      <div class="subtitle-1 mb-1">Device test reports</div>
    </div>
    <v-expansion-panels accordion focusable class="mt-2">
      <v-expansion-panel v-for="(item, i) in list" :key="i">
        <v-expansion-panel-title> {{ moment(item.createdAt).format("YYYY-MM-DD HH:mm:ss") }} </v-expansion-panel-title>
        <v-expansion-panel-text>
          <div class="mt-2">
            <div
              v-for="([name, value], ind) in Object.entries(item.parameters).sort((a, b) => a[0].localeCompare(b[0]))"
              :key="ind"
              class="data-row d-flex justify-space-between flex-nowrap"
            >
              <span class="data-name"> {{ name }}</span> <span class="data-value">{{ value }}</span>
            </div>
          </div>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
    <div v-if="!loading && !(list && list.length)" class="body-2 opacity-50 text-center">No test reports found</div>
    <div class="d-flex justify-center">
      <v-btn v-if="!loading && hasMore" class="my-2" variant="text" small @click="getData">
        <v-icon left>mdi-arrow-down-bold</v-icon>
        Load more
      </v-btn>

      <v-progress-circular v-if="loading" indeterminate color="primary" size="30" class="my-2" />
    </div>
  </div>
</template>

<script setup lang="ts">
import TestResult from "@/types/TestResult";
import deviceResource from "@/resources/DeviceResource";
import axios, { CancelTokenSource } from "axios";
import moment from "moment";
import { ref, watch } from "vue";

const props = withDefaults(
  defineProps<{
    readonly deviceId: number | null;
  }>(),
  {
    deviceId: null,
  }
);

let cancelToken: CancelTokenSource | undefined = undefined;
const loading = ref(false);
const list = ref<TestResult[]>([]);
const hasMore = ref<boolean>(false);

const getData = () => {
  // Cancel existing request
  if (cancelToken) {
    cancelToken.cancel();
  }

  if (!props.deviceId) return;

  setTimeout(() => {
    // Timeout is workaround for finaly() being executed after request was canceled and new request already began
    if (cancelToken) {
      cancelToken.cancel();
    }
    cancelToken = axios.CancelToken.source();

    if (!props.deviceId) return;

    loading.value = true;
    let createdBefore;

    if (list.value.length) {
      const minDateAsMilliseconds = Math.min(...list.value.map(({ createdAt }) => new Date(createdAt).getTime()));
      createdBefore = new Date(minDateAsMilliseconds);
    }

    deviceResource
      .getTestResultsByDeviceId(props.deviceId, createdBefore, cancelToken)
      .then((resp) => {
        list.value = [...list.value , ...resp.data.results];
        hasMore.value = resp.data.hasMore;
      })
      .catch(deviceResource.defaultErrorHandler)
      .finally(() => {
        loading.value = false;
        cancelToken = undefined;
      });
  }, 10);
};
watch(() => props.deviceId, getData, { immediate: true });
</script>
<style scoped>
.data-row {
  padding: 2px 5px;
}
.data-row:hover {
  background: rgba(100, 100, 100, 0.25);
}
.data-name {
  margin-right: 1em;
  text-transform: none;
  font-size: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.data-value {
  text-align: right;
  font-size: 14px;
  white-space: nowrap;
}
</style>
