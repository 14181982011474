import { RepairStatus } from "@/types/RepairStatus";
import { RepairResult } from "@/types/RepairResult";

export default class RepairHelper {
    static getRepairStatusDisplayName(status?: RepairStatus): string {
        switch (status) {
            case RepairStatus.Pending:
                return "Pending";
            case RepairStatus.Ongoing:
                return "Ongoing";
            case RepairStatus.Completed:
                return "Completed";
            default:
                return status || "";
        }
    }
    static getRepairStatusColor(status?: RepairStatus, isText?: boolean, result?: RepairResult | null) {
        if (isText) {
            return {
                "text-orange": RepairStatus.Pending === status,
                "text-blue": RepairStatus.Ongoing === status,
                "text-green": RepairResult.NotRepaired !== result && RepairStatus.Completed === status,
                "text-red": RepairResult.NotRepaired === result && RepairStatus.Completed === status,
            };
        }
        return {
            "bg-orange": RepairStatus.Pending === status,
            "bg-blue": RepairStatus.Ongoing === status,
            "bg-green": RepairResult.NotRepaired !== result && RepairStatus.Completed === status,
            "bg-red": RepairResult.NotRepaired === result && RepairStatus.Completed === status,
        };
    }

    static getRepairResultDisplayName(result?: RepairResult): string {
        switch (result) {
            case RepairResult.NotRepaired:
                return "Not Repaired";
            case RepairResult.Repaired:
                return "Repaired";
            default:
                return result || "";
        }
    }
    static getRepairResultColor(result?: RepairResult, isText?: boolean) {
        if (isText) {
            return {
                "text-red": RepairResult.NotRepaired === result,
                "text-green": RepairResult.Repaired === result,
            };
        }
        return {
            "bg-deepRed": RepairResult.NotRepaired === result,
            "bg-green": RepairResult.Repaired === result,
        };
    }

    static getRepairFilterByStatus() {
        return [
            { text: this.getRepairStatusDisplayName(RepairStatus.Pending), value: RepairStatus.Pending },
            { text: this.getRepairStatusDisplayName(RepairStatus.Ongoing), value: RepairStatus.Ongoing },
            { text: this.getRepairStatusDisplayName(RepairStatus.Completed), value: RepairStatus.Completed },
        ];
    }
    
    static getRepairFilterByResult() {
        return [
            { text: this.getRepairResultDisplayName(RepairResult.NotRepaired), value: RepairResult.NotRepaired },
            { text: this.getRepairResultDisplayName(RepairResult.Repaired), value: RepairResult.Repaired },
        ];
    }
}
