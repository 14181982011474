<template>
  <v-row v-if="showCalendar">
    <v-col cols="12" sm="6" md="4" class="d-flex align-center">
      <v-menu
        v-model="reactivationLockoutMenu"
        :close-on-content-click="false"
        :nudge-right="33"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
      >
        <template v-slot:activator="{ props }">
          <v-text-field
            v-model="reactivationLockoutDate"
            density="compact"
            :variant="true ? 'outlined' : 'solo'"
            :flat="!true"
            :readonly="!true"
            :error="!reactivationLockoutDate || isNaN(Date.parse(reactivationLockoutDate))"
            v-bind="props"
            :hide-details="true"
            @blur="checkDate"
          >
            <template v-slot:prepend>
              <v-icon class="mr-n2">mdi-calendar</v-icon>
            </template>
          </v-text-field>
        </template>
        <v-date-picker
          :modelValue="
            moment(reactivationLockoutDate, 'YYYY-MM-DD', true).isValid() ? moment(reactivationLockoutDate).toDate() : null
          "
          @update:modelValue="(v) => (reactivationLockoutDate = moment(v).format('YYYY-MM-DD'))"
          :hide-header="true"
          no-title
          scrollable
          :show-current="moment().format('YYYY-MM-DD')"
          @update:model-value="reactivationLockoutMenu = false"
          show-adjacent-months
          color="primary"
          :min="moment().subtract(1, 'day').toDate()"
        />
      </v-menu>
    </v-col>
    <v-col cols="12" sm="6" md="4" class="d-flex align-center">
      <v-menu
        ref="subsEndTimeMenuRef"
        v-model="reactivationLockoutTimeMenu"
        :close-on-content-click="false"
        :return-value.sync="reactivationLockoutTime"
        :nudge-right="33"
        transition="scale-transition"
        max-width="290px"
        min-width="290px"
      >
        <template v-slot:activator="{ props }">
          <v-text-field
            v-model="reactivationLockoutTime"
            readonly
            density="compact"
            :variant="canEditPoi ? 'outlined' : 'solo'"
            :flat="!canEditPoi"
            v-bind="props"
            :hide-details="true"
            color="primary"
          >
            <template v-slot:prepend>
              <v-icon class="mr-n2">mdi-clock-time-four-outline</v-icon>
            </template>
          </v-text-field>
        </template>
        <v-time-picker
          v-if="reactivationLockoutTimeMenu && canEditPoi"
          v-model="reactivationLockoutTime"
          no-title
          format="24hr"
          full-width
          :hide-header="true"
          @update:hour="onHour"
          @update:minute="saveLockoutTimeChanges"
          color="primary"
          :min="minTime"
        ></v-time-picker>
      </v-menu>
    </v-col>
    <v-col class="d-flex align-end justify-end justify-md-start">
      <v-btn color="secondary" prepend-icon="mdi-close" @click="onClear">Clear</v-btn>
    </v-col>
  </v-row>

  <v-btn v-if="!showCalendar" color="secondary" @click="onOneHourFromNow" class="my-2"> Lock for 1 hour </v-btn>
</template>

<script setup lang="ts">
import Poi from "@/types/Poi";
import moment from "moment";
import { computed, ref, watch } from "vue";

const props = withDefaults(defineProps<{ readonly modelValue?: Poi | null; canEditPoi?: boolean | null }>(), {
  modelValue: null,
  canEditPoi: false,
});

const reactivationLockoutMenu = ref(false);
const reactivationLockoutDate = ref("");

const reactivationLockoutTimeMenu = ref(false);
const reactivationLockoutTime = ref("");
const saveLockoutTimeChanges = () => {
  reactivationLockoutTimeMenu.value = false;
};

const initValue = ref<Date | null>(null);

const minTime = computed(() => {
  return moment(reactivationLockoutDate.value).isSame(moment(), "day") ? moment().format("HH:mm") : undefined;
});

const showCalendar = ref(false);

const onHour = (newHour: number) => {
  if (!reactivationLockoutTime.value) return;
  const hour = newHour < 10 ? `0${newHour}` : newHour.toString();
  reactivationLockoutTime.value = hour + reactivationLockoutTime.value.slice(2);
};
const onOneHourFromNow = () => {
  if (!props.modelValue) return;
  props.modelValue.reactivationLockout = moment().add(1, "h").toDate();
  showCalendar.value = true;
};

const onClear = () => {
  if (!props.modelValue) return;
  props.modelValue.reactivationLockout = null;
  showCalendar.value = false;
};

watch(
  () => [props.modelValue, props.modelValue?.reactivationLockout],
  function onChangeValue() {
    if (props.modelValue) {
      if (props.modelValue.reactivationLockout) {
        const dt = moment(props.modelValue.reactivationLockout);
        reactivationLockoutDate.value = dt.format("YYYY-MM-DD");
        reactivationLockoutTime.value = dt.format("HH:mm");
      } else {
        reactivationLockoutDate.value = "";
        reactivationLockoutTime.value = "";
      }
    }

    // initValue handler
    if (initValue.value && !props.modelValue?.reactivationLockout) initValue.value = null;
    if (!initValue.value && props.modelValue?.reactivationLockout) {
      initValue.value = props.modelValue.reactivationLockout;
      if (moment(props.modelValue.reactivationLockout).isAfter(moment()) && !showCalendar.value) showCalendar.value = true;
    }
  },
  { immediate: true }
);

const checkDate = () => {
  if (!moment(reactivationLockoutDate.value, "YYYY-MM-DD", true).isValid()) {
    reactivationLockoutDate.value = moment(initValue.value).isAfter(moment())
      ? moment(initValue.value).format("YYYY-MM-DD")
      : moment().format("YYYY-MM-DD");
  }
};

watch([reactivationLockoutTime, reactivationLockoutDate], () => {
  if (!moment(reactivationLockoutDate.value, "YYYY-MM-DD", true).isValid()) return;

  let [hour, minutes] = reactivationLockoutTime.value.split(":");
  if (!hour) hour = "0";
  if (!minutes) minutes = "0";
  if (props.modelValue) {
    props.modelValue.reactivationLockout = moment(reactivationLockoutDate.value, "YYYY-MM-DD", true)
      .set({
        hour: Number(hour),
        minute: Number(minutes),
      })
      .toDate();
  }
});
</script>
