<template>
  <v-container fluid>
    <v-card>
      <v-card-title class="d-block d-sm-flex">
        <div class="d-flex justify-space-between flex-grow-1">
          <div class="d-flex flex-grow-1">
            <v-text-field
              v-model="searchTerm"
              append-inner-icon="mdi-magnify"
              variant="underlined"
              color="primary"
              label="Search"
              hide-details
              clearable
              @click:clear="search()"
              style="max-width"
              v-on:input="search()"
              v-on:keypress.enter="search(true)"
            ></v-text-field>
            <v-btn
              size="x-small"
              variant="text"
              icon
              class="align-self-end ml-4"
              @click="reload"
              :disabled="loading"
              title="Refresh"
            >
              <v-icon size="24">mdi-reload</v-icon>
            </v-btn>
            <TableConfiguration :allHeaders="headers" v-model="selectedHeaders" tableKey="repairsTableColumns" />
            <v-tooltip location="bottom" :disabled="!searchTerm">
              <template v-slot:activator="{ props }">
                <span class="align-self-end ml-2 p-relative" v-bind="props">
                  <v-btn
                    size="x-small"
                    variant="text"
                    icon
                    class="align-self-end"
                    @click="showFilter = !showFilter"
                    :color="showFilter ? 'primary' : undefined"
                    :disabled="loading || Boolean(searchTerm)"
                    title="Filters"
                  >
                    <v-icon size="24" v-if="searchTerm">mdi-filter-off</v-icon>

                    <v-icon size="24" v-if="!searchTerm">mdi-filter-variant</v-icon>
                    <v-badge
                      color="primary"
                      v-if="numberOfFilter && !searchTerm"
                      transition="v-fade-transition"
                      dot
                      bordered
                      offset-x="-1"
                      offset-y="-10"
                    />
                  </v-btn>
                </span>
              </template>
              Filters ignored during search
            </v-tooltip>
          </div>
        </div>

        <v-spacer class="d-none d-sm-block"></v-spacer>
      </v-card-title>

      <Filters :show="showFilter" :filter="filter" @close="showFilter = false" @update="updateFilter" :disabled="loading" />

      <v-data-table-server
        density="compact"
        :row-props="rowClass"
        :headers="selectedHeaders"
        :items="items"
        :items-length="total || 0"
        :loading="loading && 'primary'"
        :mobile-breakpoint="0"
        @click:row="(_ : any, { item }:any) => rowClick(item)"
        @contextmenu:row="openContenxMenu"
        v-model:sort-by="sortBy"
        :must-sort="true"
        hover
        :mobile="false"
      >
        <template v-slot:[`item.repairId`]="{ item }">
          <div class="no-wrap">{{ item.repairId }}</div>
        </template>

        <template v-slot:[`item.deviceId`]="{ item }">
          <div class="no-wrap">{{ item.deviceId }}</div>
        </template>

        <template v-slot:[`item.createdAt`]="{ item }">
          <span>
            {{ `${moment(item?.createdAt).format("YYYY-MM-DD HH:mm")}` }}
          </span>
        </template>

        <template v-slot:[`item.lastModifiedAt`]="{ item }">
          <span v-if="item.lastModifiedAt">
            {{ `${moment(item?.lastModifiedAt).format("YYYY-MM-DD HH:mm")}` }}
          </span>
        </template>

        <template v-slot:[`item.notes`]="{ item }">
          <v-tooltip v-if="item.notes && item.notes.length > 15" location="bottom" color="secondary" max-width="500">
            <template v-slot:activator="{ props }">
              <div class="no-wrap" v-bind="props">
                {{ item.notes.substring(0, 15) + "..." }}
              </div>
            </template>
            <span class="pre-wrap">{{ item.notes }}</span>
          </v-tooltip>
          <span v-else>{{ item.notes }}</span>
        </template>

        <template v-slot:[`item.status`]="{ item }">
          <div :class="statusColor(item)">{{ getDeviceStatusName(item.status) }}</div>
        </template>

        <template v-slot:[`item.result`]="{ item }">
          <div :class="resultColor(item.result)">{{ getRepairResultName(item.result) }}</div>
        </template>

        <template v-slot:bottom>
          <DataTableFooter
            v-model:page="page"
            :items="items"
            :itemsLength="total"
            v-model:itemsPerPage="itemsPerPage"
            :itemsPerPageOptions="[15, 25, 50]"
          />
        </template>
      </v-data-table-server>

      <v-overlay contained :model-value="loading" opacity="0" persistent :style="'z-index: 999 !important;'" />
    </v-card>
    <EditRepair
      :editRepair="editRepair"
      :device="editRepair?.deviceId ? { deviceId: editRepair?.deviceId } as Device : null"
      @close:editRepair="editRepair = null"
      @delete="reload"
      @create="reload"
      @update="reload"
    />
  </v-container>
</template>

<script setup lang="ts">
import axios, { CancelTokenSource } from "axios";
import Device from "@/types/Device";
import moment from "moment";
import Repair from "@/types/Repair";
import repairHelper from "@/helpers/repairHelper";
import userStorage from "@/services/UserStorageService";
import TableConfiguration from "@/components/common/TableConfiguration.vue";
import EditRepair from "@/components/service/EditRepair.vue";
import TableFilter from "@/types/TableFilter";
import Filters from "@/components/common/Filters/Filters.vue";
import DataTableFooter from "@/components/common/DataTableFooter.vue";
import { ref, computed, watch, nextTick, onActivated, onDeactivated } from "vue";
import repairResource from "@/resources/RepairResource";
import { RepairStatus } from "@/types/RepairStatus";
import { RepairResult } from "@/types/RepairResult";
import locationResource from "@/resources/LocationResource";

const props = withDefaults(
  defineProps<{
    readonly initFilter: { [key: string]: TableFilter["selected"] } | null;
    readonly initData?: { repairId?: number } | null;
  }>(),
  {
    initFilter: null,
    initData: null,
  }
);

const showFilter = ref(Boolean(Object.values(props.initFilter || {}).length));

const getRepairUsers = (search?: string, filterCancelToken?: CancelTokenSource) => {
  return repairResource
    .getRepairUsers(search?.trim(), 5, filterCancelToken)
    .then((resp) => resp.data.map((user) => ({ text: user.username, value: user.userId })))
    .catch(repairResource.defaultErrorHandler);
};

const getLocations = async () => {
  const locations = await locationResource.getLocations();
  return locations.map((v) => ({ text: v, value: v }));
};

const filter = ref<TableFilter[]>([
  {
    title: "Status",
    icon: "mdi-checkbox-multiple-marked",
    filterName: "status",
    searchable: false,
    selected: props.initFilter?.status || [],
    itemsCallback: (search?: string) => {
      if (!search) return repairHelper.getRepairFilterByStatus();
      return repairHelper.getRepairFilterByStatus().filter(({ text }) => text.toLowerCase().includes(search.toLowerCase()));
    },
  },
  {
    title: "Result",
    icon: "mdi-checkbox-multiple-marked-outline",
    disableMultiple: true,
    filterName: "result",
    searchable: false,
    selected: props.initFilter?.result || [],
    itemsCallback: (search?: string) => {
      if (!search) return repairHelper.getRepairFilterByResult();
      return repairHelper.getRepairFilterByResult().filter(({ text }) => text.toLowerCase().includes(search.toLowerCase()));
    },
  },
  {
    title: "Users",
    icon: "mdi-account-multiple",
    filterName: "createdBy",
    searchable: true,
    selected: props.initFilter?.createdBy || [],
    itemsCallback: getRepairUsers,
  },
  {
    title: "Location",
    icon: "mdi-map-marker",
    filterName: "location",
    searchable: true,
    selected: props.initFilter?.location || [],
    itemsCallback: getLocations,
  },
  {
    title: "From",
    icon: "",
    filterName: "dateFrom",
    asDateRangeFilter: true,
    selected: props.initFilter?.dateFrom || [],
  },

  {
    title: "To excl.",
    icon: "",
    filterName: "dateTo",
    asDateRangeFilter: true,
    selected: props.initFilter?.dateFrom || [],
  },
]);

const numberOfFilter = computed(() =>
  Object.values(filter.value).reduce((acc, { selected }) => (acc += selected.length), 0)
);

const total = ref<number | undefined>(0);
const items = ref<Repair[]>([]);
const loading = ref(false);
const editRepair = ref<Repair | null>(null);
const optionsStorageKey = "repairsTable";
const itemsPerPage = ref(userStorage.get(optionsStorageKey)?.itemsPerPage ?? 15);
const sortBy = ref<{ key: string; order: boolean | "asc" | "desc" }[]>(
  userStorage.get(optionsStorageKey)?.sortBy?.[0]?.key
    ? userStorage.get(optionsStorageKey)?.sortBy
    : [{ key: "repairId", order: true }]
);

const page = ref(userStorage.get(optionsStorageKey)?.page ?? 1);

const searchTermStorageKey = "repairsTableSearchTerm";
const searchTerm = ref(userStorage.get(searchTermStorageKey) ?? "");
let searchThrottleTimer = 0;
let cancelToken: CancelTokenSource | undefined = undefined;

const repairToEdit = ref<Repair | null>(null);

const ignoreOptionsChange = ref(false);
let dataReloadTimeoutId: number | null = null;
const dataReloadIntervalSeconds = 180;
const componentActive = ref(false);
const contextMenuEventItem: any = ref(null);

const openContenxMenu = (e: any) => {
  contextMenuEventItem.value = e;
};

const restartDataReloadTimeout = () => {
  if (dataReloadTimeoutId) {
    clearTimeout(dataReloadTimeoutId);
  }

  dataReloadTimeoutId = setTimeout(() => {
    dataReloadTimeoutId = 0;
    if (componentActive.value) {
      getData();
    }
  }, dataReloadIntervalSeconds * 1000);
};

const getData = (resetPagination: boolean = false) => {
  if (cancelToken) {
    cancelToken.cancel();
    cancelToken = undefined;
  }

  // Reset pagination
  if (resetPagination) {
    ignoreOptionsChange.value = true;
    page.value = 1;
  }

  // Save sorting, filters and search terms
  userStorage.set(optionsStorageKey, {
    page: page.value,
    itemsPerPage: itemsPerPage.value,
    sortBy: sortBy.value,
  });
  userStorage.set(searchTermStorageKey, searchTerm.value);

  // Restart data reload timeout
  restartDataReloadTimeout();

  setTimeout(() => {
    // Timeout is workaround for finaly() being executed after request was canceled and new request already began
    loading.value = true;
    cancelToken = axios.CancelToken.source();

    let location = undefined;
    let resultFilter = undefined;
    let statusFilter = undefined;
    let createdByIds = undefined;
    let from = undefined;
    let to = undefined;
    if (!searchTerm.value) {
      location = filter.value.find(({ filterName }) => filterName === "location")?.selected.map(({ value }) => value);
      resultFilter = filter.value.find(({ filterName }) => filterName === "result")?.selected.map(({ value }) => value)[0];
      statusFilter = filter.value.find(({ filterName }) => filterName === "status")?.selected.map(({ value }) => value);
      createdByIds = filter.value.find(({ filterName }) => filterName === "createdBy")?.selected.map(({ value }) => value);

      from = filter.value.find(({ filterName }) => filterName === "dateFrom")?.selected.map(({ value }) => value)[0];
      to = filter.value.find(({ filterName }) => filterName === "dateTo")?.selected.map(({ value }) => value)[0];
    }

    const orderDesc =
      typeof sortBy.value[0].order === "boolean" ? sortBy.value[0].order : sortBy.value[0].order.toString() === "desc";
    repairResource
      .getRepairPaged(
        itemsPerPage.value,
        page.value,
        searchTerm.value,
        sortBy.value[0].key,
        orderDesc,
        location,
        resultFilter,
        statusFilter,
        createdByIds,
        from,
        to,
        cancelToken
      )
      .then((resp) => {
        items.value = resp.data.items;
        total.value = resp.data.totalItems;
      })
      .catch(repairResource.defaultErrorHandler)
      .finally(() => {
        loading.value = false;
        cancelToken = undefined;
        ignoreOptionsChange.value = false;
      });
  }, 10);
};

const resultColor = (result: RepairResult | null) => {
  if (!result && result !== 0) return;
  return repairHelper.getRepairResultColor(result, true);
};

const getRepairResultName = (result: RepairResult | null) => {
  if (!result && result !== 0) return;
  return repairHelper.getRepairResultDisplayName(result);
};

const getDeviceStatusName = (status: RepairStatus) => {
  return repairHelper.getRepairStatusDisplayName(status);
};

const statusColor = (repair: Repair) => {
  return repairHelper.getRepairStatusColor(repair.status, true, repair.result);
};

watch([itemsPerPage, sortBy, page], async () => {
  if (!ignoreOptionsChange.value) {
    await nextTick();
    getData();
  }
});

const selectedHeaders = ref([]);
const headers = [
  { title: "ID", align: "start", key: "repairId" },
  { title: "Device ID", key: "deviceId" },
  { title: "Status", key: "status" },
  { title: "Result", key: "result" },
  { title: "Location", key: "location" },
  { title: "Created", key: "createdAt" },
  { title: "Created By", key: "createdBy" },
  { title: "Updated", key: "lastModifiedAt" },
  { title: "Updated By", key: "lastModifiedBy" },
  { title: "Notes", key: "notes", sortable: false },
];

const getRepairById = (repairId: number) => {
  repairResource
    .getRepairById(repairId)
    .then((resp) => {
      repairToEdit.value = resp.data;
    })
    .catch(repairResource.defaultErrorHandler);
};

const search = (noTheshold: boolean = false) => {
  if (searchThrottleTimer) {
    clearTimeout(searchThrottleTimer);
    searchThrottleTimer = 0;
  }

  if (noTheshold || !searchTerm.value) {
    getData(true);
  } else {
    searchThrottleTimer = setTimeout(() => {
      getData(true);
    }, 1000);
  }
};

const reload = () => getData();

const rowClick = (repair: Repair) => {
  editRepair.value = repair;
};

const rowClass = ({ item }: { item: Repair }) => {
  return { class: { "cursor-default": true } };
};

const updateFilter = ({ newFilter, eventOptions }: { newFilter: TableFilter[]; eventOptions: any }) => {
  if (searchThrottleTimer) {
    clearTimeout(searchThrottleTimer);
    searchThrottleTimer = 0;
  }

  searchThrottleTimer = setTimeout(
    () => {
      filter.value = newFilter;
      if (page.value > 1) {
        page.value = 1;
        return;
      }
      getData();
    },
    eventOptions.byRemoveBtn ? 0 : 1000
  );
};

onActivated(() => {
  componentActive.value = true;

  if (props.initData?.repairId) {
    getRepairById(props.initData?.repairId);
  }

  // reload data (user haven't been on the page logner than dataReloadIntervalSeconds)
  if (dataReloadTimeoutId === 0 || dataReloadTimeoutId === null) {
    getData();
  }
});

onDeactivated(() => {
  componentActive.value = false;
});
</script>

<style scoped></style>
