<template>
  <v-overlay :model-value="show" @update:model-value="onCancel" :opacity="0.33">
    <v-dialog
      scrim="rgba(33, 33, 33)"
      eager
      :model-value="show"
      @update:model-value="onCancel"
      :max-width="870"
      @keydown.esc="show = false"
      hide-overlay
      :fullscreen="isMobileAndDown"
    >
      <v-card tile>
        <v-toolbar flat color="primary" :height="4"> </v-toolbar>
        <div class="pa-2 flex-grow-1 overflow-auto">
          <v-card-text v-if="editRepair?.repairId" class="text-h6 pb-4"> Repair ID {{ editRepair?.repairId }} </v-card-text>
          <v-card-text v-else class="text-h6 pb-4">New Repair</v-card-text>
          <div class="d-flex flex-column flex-md-row-reverse">
            <div>
              <SideRepairSection
                :repair="repair"
                :originRepair="editRepair"
                :device="device"
                @delete="onDelete"
                :errors="errors"
                :readonly="!isEditable"
              />
            </div>
            <div class="mr-1 main">
              <RepairTemplateSelect v-if="isEditable && canViewRepairs" @applyTemplate="applyTemplate" />

              <RepairWorkItems
                v-if="canViewRepairs"
                :repair="repair"
                :errors="errors"
                :readonly="!isEditable"
                @select="onSelectWorkItems"
              />

              <v-card-text v-if="canViewIssue" class="text-subtitle-1 pt-0 pb-0">
                Related issues
                <v-data-table
                  density="compact"
                  :headers="headers"
                  :items="repair?.issues || []"
                  :disable-pagination="true"
                  :hide-default-footer="true"
                  items-per-page="-1"
                  :mobile-breakpoint="0"
                >
                  <template #no-data>
                    <div class="text-body-2 text-disabled text-left">No issues linked yet</div>
                  </template>

                  <template v-slot:[`item.issueDeviceStatus`]="{ item }">
                    <div :class="getIssueDeviceStatusColor(item.issueDeviceStatus)">
                      {{ getIssueDeviceStatusName(item.issueDeviceStatus) }}
                    </div>
                  </template>

                  <template v-slot:[`item.actions`]="{ item, index }">
                    <div class="d-flex justify-end">
                      <v-btn
                        link
                        :href="`/support/issues/${item.issueId}`"
                        @click="$event.stopPropagation()"
                        target="_blank"
                        icon
                        title="Open in new tab"
                        density="compact"
                        size="small"
                        variant="text"
                      >
                        <v-icon size="small">mdi-open-in-new</v-icon>
                      </v-btn>

                      <v-btn
                        v-if="isEditable"
                        @click.stop="() => deleteRelatedIssue(index)"
                        icon
                        density="compact"
                        size="small"
                        variant="text"
                        class="ml-2"
                      >
                        <v-icon size="small">mdi-close-thick</v-icon>
                      </v-btn>
                    </div>
                  </template>

                  <template #bottom></template>
                </v-data-table>

                <div v-if="isEditable && canViewIssue" class="mt-2">
                  <div class="d-flex align-center">
                    <IssueSelect
                      :key="issueSelectKey"
                      @create="addNewIssue"
                      @change="(v) => (selectedIssue = v)"
                      hint="Select an existing issue or create a new issue and automatically link repair and device id to
                    the issue."
                      label="Link existing issue or create a new one"
                      :menuProps="{ maxWidth: '770' }"
                      @update:title="(v) => (issueTitle = v)"
                      :noFilter="true"
                    />
                    <v-btn
                      class="ml-2"
                      density="comfortable"
                      size="small"
                      icon="mdi-plus"
                      variant="elevated"
                      color="white"
                      @click="addIssueToRepair"
                    ></v-btn>
                  </div>
                </div>
              </v-card-text>
            </div>
          </div>
        </div>
        <div class="text-right my-4 mx-5 ma-md-6 mt-4">
          <v-btn variant="text" @click="cancel"> {{ isEditable ? "Cancel" : "Close" }} </v-btn>
          <v-btn
            v-if="isEditable"
            color="primary"
            class="ml-4"
            @click="submitConfirm"
            :loading="repairCreating || repairUpdating"
            :disabled="repairCreating || repairUpdating"
          >
            Submit
          </v-btn>
          <FinishRepairModal :show="showFinishModal" :repair="repair" @cancel="showFinishModal = false" @confirm="confirm" />
          <v-overlay
            contained
            :model-value="repairCreating || repairUpdating || repairLoading"
            opacity="0.46"
            z-index="109999"
            persistent
            class="d-flex align-center justify-center"
          >
            <v-progress-circular indeterminate color="primary" size="64" />
          </v-overlay>
        </div>
      </v-card>
    </v-dialog>
  </v-overlay>
</template>

<script setup lang="ts">
import { RepairStatus } from "@/types/RepairStatus";
import { UserPermissionType } from "@/types/UserPermissionType";
import userProfileService from "@/services/UserProfileService";
import { computed, ref, watch } from "vue";
import Issue from "@/types/Issue";
import RepairIssue from "@/types/RepairIssue";
import axios, { CancelTokenSource } from "axios";
import RepairWorkItems from "@/components/service/RepairWorkItems.vue";
import RepairTemplateSelect from "@/components/service/RepairTemplateSelect.vue";
import SideRepairSection from "@/components/service/SideRepairSection.vue";
import FinishRepairModal from "@/components/service/FinishRepairModal.vue";
import IssueSelect from "@/components/common/IssueSelect.vue";
import infoMessageService from "@/services/InfoMessageService";
import { InfoMessageType } from "@/types/InfoMessageType";
import Device from "@/types/Device";
import { IssueDeviceStatus } from "@/types/IssueDeviceStatus";
import issueResource from "@/resources/IssueResource";
import repairResource from "@/resources/RepairResource";
import IssueHelper from "@/helpers/issueHelper";
import Repair from "@/types/Repair";
import RepairItem from "@/types/RepairItem";
import RepairTemplate from "@/types/RepairTemplate";
import authService from "@/services/AuthService";
import type { VDataTable } from "vuetify/components";
import { useDisplay } from "vuetify";
import ChangeManager from "@/services/ChangeManager";
import RepairWorkType from "@/types/RepairWorkType";
import { useConfirm } from "@/services/ConfirmService";
import userStorage from "@/services/UserStorageService";

type ReadonlyHeaders = VDataTable["$props"]["headers"];

const emit = defineEmits(["close:editRepair", "create", "delete", "update"]);
const props = withDefaults(defineProps<{ device?: Device | null; editRepair?: RepairItem | null }>(), {
  device: null,
  editRepair: null,
});

const confirmationDialog = useConfirm();

let changesControl: ChangeManager | null = null;

const isMobileAndDown = useDisplay().smAndDown;
const delayHide = ref(false);
const show = computed({
  get() {
    return !delayHide.value && Boolean(props.editRepair);
  },
  set(value) {
    delayHide.value = true;
    setTimeout(() => {
      repair.value = null;
      errors.value = { work: "", location: "" };
      emit("close:editRepair", value || null);
      delayHide.value = false;
    }, 200);
  },
});
const showFinishModal = ref(false);
const repair = ref<Repair | null>(null);
const errors = ref({ location: "", work: "" });

const repairLoading = ref(false);
const repairCreating = ref(false);
const repairUpdating = ref(false);

const selectedIssue = ref<Issue | null>(null);
const issueSelectKey = ref<number>(new Date().getTime());
const selectedWorkItem = ref<RepairWorkType | null>(null);

const repairLocationKey = 'repairLocationKey'

// begin change management
watch(repair, (val: Repair | null, oldValue: Repair | null) => {
  changesControl = ChangeManager.modalController({
    controller: changesControl,
    isNewValue: val && oldValue === null,
    isDestroy: oldValue && val === null,
    isUpdateValue: oldValue && val && oldValue.repairId !== val.repairId,
    data: { repair: val },
    message: "You have unsaved Repair changes.",
    target: `repair-${val?.repairId}`,
    onLeave: () => cancel(),
    onSave: () => submitConfirm(),
  });
});

const setChangesStatus = () => {
  if (!repair.value) return;

  const origRepair = changesControl?.data?.origData?.repair;

  if (!changesControl || !origRepair) return;

  if (!ChangeManager.isObjectEqual(origRepair, repair.value || {}, { isOrigPartial: true })) {
    changesControl?.activate();
    return;
  }

  changesControl?.deactivate();
};

watch(repair, setChangesStatus, { deep: true });
// end change management

const isEditable = computed(() => {
  return props.editRepair?.status !== RepairStatus.Completed && canEditRepairs.value;
});

const canViewRepairs = computed(() => {
  return userProfileService.hasPermission(UserPermissionType.ViewRepairs);
});

let repairToken: CancelTokenSource | undefined = undefined;

const headers: ReadonlyHeaders = [
  { title: "ID", key: "issueId", align: "start", sortable: false },
  { title: "Title", key: "name", sortable: false },
  { title: "Device issue status", key: "issueDeviceStatus", sortable: false },
  { title: "", key: "actions", sortable: false },
];

const issueTitle = ref("");

const getIssueDeviceStatusName = (status: IssueDeviceStatus) => {
  return IssueHelper.getDeviceStatusDisplayName(status);
};

const getIssueDeviceStatusColor = (status: IssueDeviceStatus) => {
  return IssueHelper.getDeviceStatusColor(status);
};

const canAddRepair = computed(() => {
  return userProfileService.hasPermission(UserPermissionType.AddRepairs);
});

const canEditRepairs = computed(() => {
  return userProfileService.hasPermission(UserPermissionType.EditRepairs);
});

const canAddIssue = computed(() => {
  return userProfileService.hasPermission(UserPermissionType.AddIssues);
});

const canViewIssue = computed(() => {
  return userProfileService.hasPermission(UserPermissionType.ViewIssues);
});

const cancel = () => {
  show.value = false;
  showFinishModal.value = false;
};

const onCancel = () => {
  if (ChangeManager.state().isChanged) {
    ChangeManager.show();
    return;
  }

  cancel();
};

const deleteRelatedIssue = (index: number) => {
  if (!repair.value) return;
  repair.value.issues = repair.value.issues.filter((v: RepairIssue, ind) => index !== ind);
};

const getRepairData = () => {
  if (!props.editRepair) return;

  if (repairToken) {
    repairToken.cancel();
  }
  if (props.editRepair && !props.editRepair?.repairId) {
    repair.value = {
      ...props.editRepair,
      issues: [],
      location: userStorage.get(repairLocationKey) || null,
      repairWorks: [],
      notes: null,
      isIrreparable: false,
    };
    return;
  }

  repairToken = axios.CancelToken.source();
  repairLoading.value = true;
  repairResource
    .getRepairById(props.editRepair?.repairId, repairToken)
    .then((resp) => {
      repair.value = resp.data;
    })
    .catch(issueResource.defaultErrorHandler)
    .finally(() => {
      repairLoading.value = false;
      repairToken = undefined;
    });
};

watch(
  () => props.editRepair,
  () => {
    if (!props.editRepair) {
      return;
    }
    getRepairData();
  },
  { immediate: true }
);

const clearIssueSelect = () => {
  selectedIssue.value = null;
  issueSelectKey.value = new Date().getTime();
  issueTitle.value = "";
};
const addIssueToRepair = () => {
  if (selectedIssue.value) {
    if (repair.value?.issues.find((v: RepairIssue) => v.issueId === selectedIssue.value?.issueId)) {
      infoMessageService.show(InfoMessageType.Warning, "The issue is connected already.");
      return;
    }

    repair.value?.issues.push({
      ...selectedIssue.value,
      issueDeviceStatus: IssueDeviceStatus.New,
    });
    clearIssueSelect();
  }

  if (issueTitle.value) {
    addNewIssue();
  }
};
const addNewIssue = () => {
  if (!canAddIssue.value || !issueTitle.value || !repair) {
    return;
  }
  repair.value?.issues.push({
    issueId: 0,
    name: issueTitle.value,
    issueDeviceStatus: IssueDeviceStatus.New,
  });

  clearIssueSelect();
};

const createNewRepair = () => {
  if (!canAddRepair.value || !repair.value || !props.device?.deviceId) return;

  repairCreating.value = true;

  // Save location
  userStorage.set(repairLocationKey, repair.value.location);

  repairResource
    .addRepair(repair.value)
    .then((resp) => {
      emit("create", repair.value);
      show.value = false;
    })
    .catch(repairResource.defaultErrorHandler)
    .finally(() => {
      repairCreating.value = false;
    });
};

const updateRepair = () => {
  if (!canEditRepairs.value || !repair.value || !props.device?.deviceId) return;
  repairUpdating.value = true;


  // Save location
  userStorage.set(repairLocationKey, repair.value.location);
  
  repairResource
    .updateRepair(repair.value)
    .then((resp) => {
      emit("update", repair.value);
      show.value = false;
    })
    .catch(repairResource.defaultErrorHandler)
    .finally(() => {
      repairUpdating.value = false;
    });
};

const formValidation = () => {
  if (!props.editRepair) return;
  if (!props.editRepair?.repairId && !canAddRepair.value) return;
  if (props.editRepair?.repairId && !canEditRepairs.value) return;
  if (!repair.value?.location) {
    errors.value.location = "Field is required";
  }
  const isWork = repair.value?.repairWorks?.length;

  if (repair.value?.status === RepairStatus.Completed && !isWork) {
    errors.value.work = "Work is required";
  }

  if (!repair.value?.location || (repair.value?.status === RepairStatus.Completed && !isWork)) {
    return;
  }
  return true;
};

const confirm = () => {
  if (!formValidation()) return;
  showFinishModal.value = false;
  if (!props.editRepair?.repairId) createNewRepair();
  if (props.editRepair?.repairId) updateRepair();
};

const submit = () => {
  if (!formValidation()) return;

  if (repair.value?.status === RepairStatus.Completed) {
    showFinishModal.value = true;
    return;
  }

  if (!props.editRepair?.repairId) createNewRepair();
  if (props.editRepair?.repairId) updateRepair();
};

const submitConfirm = () => {
  if (issueTitle.value || selectedIssue.value || selectedWorkItem.value) {
    confirmationDialog
      .show(
        `You have selected a work item and/or issue, but did not add it.<br/><br/>Are you sure you want to continue?`,
        {
          buttonTrueText: "Continue",
          buttonFalseText: "Back",
          width: 500,
        }
      )
      .then((confirmed) => {
        if (confirmed) {
          submit();
        }
      });
    return;
  }
  submit();
};

const applyTemplate = (template: RepairTemplate) => {
  if (repair?.value?.issues) {
    repair.value.issues = template.issues.map((issue) => ({
      ...issue,
      issueDeviceStatus: IssueDeviceStatus.New,
    }));
    repair.value.repairWorks = template.repairWorkTypes.map((work) => ({
      ...work,
      repairWorkId: 0,
      createdAt: new Date(),
      createdBy: authService.authInfo.username || "",
    }));
  }
};

const onSelectWorkItems = (newWorkItem: RepairWorkType) => {
  selectedWorkItem.value = newWorkItem
};
const onDelete = () => {
  emit("delete");
  cancel();
};
</script>

<style scoped>
.main {
  flex: 1;
  width: 580px;
  max-width: 100%;
}
.title {
  width: 100px;
  font-size: 13px;
}
</style>
