<template>
  <span>
    <span v-if="consent">
      <span>&nbsp;{{ consent.hasConsented ? "Given" : consent.lastModifiedAt ? "Revoked": "N/A" }}</span>
      <span v-if="consent.lastModifiedAt" class="ml-2">{{ moment(consent.lastModifiedAt).format("lll") }}</span>
    </span>
    <v-btn 
      v-if="canEditDevice"
      @click="refresh"
      :loading="loading"
      :disabled="loading"
      size="small"
      variant="tonal"
      density="compact"
      icon="mdi-refresh"
      class="ml-2"
    />
    <v-btn 
      v-if="canEditDevice && consent && consent.hasConsented"
      @click="revokeConfirmation"
      :loading="loading"
      :disabled="loading"
      variant="tonal"
      size="small"
      density="comfortable"
      class="ml-2"
    >Revoke</v-btn>
  </span>
</template>

<script setup lang="ts">
import moment, { min } from "moment";
import deviceResource from "@/resources/DeviceResource";
import { ref, computed, watch } from "vue";
import { useConfirm } from "@/services/ConfirmService";
import DeviceConsent from "@/types/DeviceConsent";
import userProfileService from "@/services/UserProfileService";
import { UserPermissionType } from "@/types/UserPermissionType";

const confirm = useConfirm();
const props = withDefaults(defineProps<{ readonly deviceId: number | null }>(), { deviceId: null });

const loading = ref(false);
const consent = ref<DeviceConsent | null>(null);
const canEditDevice = computed(() => userProfileService.hasPermission(UserPermissionType.EditDevices));

const getLatestConsent = () => {
  if (!props.deviceId) {
    return;
  }

  loading.value = true;

  deviceResource
    .getLatestDeviceConsent(props.deviceId)
    .then((resp) => {
      consent.value = resp.data;
    })
    .catch(deviceResource.defaultErrorHandler)
    .finally(() => {
      loading.value = false;
    });
};

watch(
  () => props.deviceId,
  function onValueChanged() {
    getLatestConsent();
  },
  { immediate: true }
);

const revokeConfirmation = () => {
  confirm
    .show("Revoke consent?")
    .then((confirmed) => {
      if (confirmed) {
        deactivate();
      }
    });
};

const deactivate = () => {
  loading.value = true;

  deviceResource
    .revokeDeviceConsent(props.deviceId!)
    .then(() => {
      getLatestConsent();
    })
    .catch(deviceResource.defaultErrorHandler)
    .finally(() => {
      loading.value = false;
    });
};

const refresh = () => {
  getLatestConsent();
};
</script>

<style scoped>
</style>
