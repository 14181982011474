<template>
  <v-overlay :model-value="show" @update:model-value="onCancel" :opacity="0.33">
    <v-dialog
      scrim="rgba(33, 33, 33)"
      eager
      :model-value="show"
      @update:model-value="onCancel"
      :max-width="700"
      @keydown.esc="show = false"
      hide-overlay
      :fullscreen="isMobileAndDown"
    >
      <v-card tile>
        <v-toolbar flat color="primary" :height="4"> </v-toolbar>
        <div class="pa-2 flex-grow-1 overflow-auto">
          <v-card-text class="text-h6">New Repair</v-card-text>
          <div class="mx-4 mt-1">
            <div class="d-flex align-center">
              <div class="title">Status</div>
              <div>
                <v-menu offset-y>
                  <template v-slot:activator="{ props }">
                    <v-btn v-bind="props" :class="statusColor" size="small">
                      {{ getRepairStatusName }}
                      <v-icon class="d-none d-md-block ml-1" size="small" end>mdi-chevron-down</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      @click="() => updateStatus(item.value)"
                      v-for="(item, index) in statusList"
                      :key="index"
                      density="compact"
                    >
                      <v-list-item-title
                        class="text-uppercase"
                        :class="RepairHelper.getRepairStatusColor(item.value, true)"
                        >{{ item.text }}</v-list-item-title
                      >
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </div>
            <div class="d-flex mt-2 align-center">
              <div class="title">Location</div>

              <v-select
                v-model="location"
                @update:model-value="!!errors.location"
                :items="locations"
                density="compact"
                max-width="150"
                :error="!!errors.location"
                :error-messages="errors.location"
                placeholder="Choose"
                class="compact-select"
                variant="underlined"
                menu-icon="mdi-menu-down"
                hide-details
              >
                <template v-slot:selection="{ item }">
                  <span class="location">{{ item.title }}</span>
                </template>
              </v-select>
            </div>
            <div class="d-flex mt-4">
              <div class="title">Notes</div>
              <v-textarea
                color="primary"
                v-model="notes"
                rows="2"
                variant="outlined"
                auto-grow
                shaped
                class="notes"
              ></v-textarea>
            </div>
          </div>

          <v-card-text v-if="canViewIssue" class="text-subtitle-1"
            >Related issues
            <v-data-table
              density="compact"
              :headers="headers"
              :items="relatedIssues"
              :disable-pagination="true"
              :hide-default-footer="true"
              items-per-page="-1"
              :mobile-breakpoint="0"
            >
              <template #no-data>
                <div class="text-body-2 text-disabled text-left">No issues linked yet</div>
              </template>

              <template v-slot:[`item.issueDeviceStatus`]="{ item }">
                <div :class="getIssueDeviceStatusColor(item.issueDeviceStatus)">
                  {{ getIssueDeviceStatusName(item.issueDeviceStatus) }}
                </div>
              </template>

              <template v-slot:[`item.actions`]="{ item, index }">
                <div class="d-flex justify-end">
                  <v-btn
                    link
                    :href="`/support/issues/${item.issueId}`"
                    @click="$event.stopPropagation()"
                    target="_blank"
                    icon
                    title="Open in new tab"
                    density="compact"
                    size="small"
                    variant="text"
                  >
                    <v-icon size="small">mdi-open-in-new</v-icon>
                  </v-btn>

                  <v-btn
                    @click.stop="() => deleteRelatedIssue(index)"
                    icon
                    density="compact"
                    size="small"
                    variant="text"
                    class="ml-2"
                  >
                    <v-icon size="small">mdi-close-thick</v-icon>
                  </v-btn>
                </div>
              </template>

              <template #bottom></template>
            </v-data-table>

            <div v-if="canViewIssue" class="mt-2 d-flex align-center">
              <IssueSelect
                :key="issueSelectKey"
                @create="addNewIssue"
                @change="(v) => (selectedIssue = v)"
                hint="Select an existing issue or create a new issue and automatically link repair and device id to
                      the issue."
                label="Link existing issue or create a new one"
                :menuProps="{ maxWidth: '770' }"
                @update:title="(v) => (issueTitle = v)"
                :noFilter="true"
              />
              <v-btn
                class="ml-2"
                density="comfortable"
                size="small"
                icon="mdi-plus"
                variant="elevated"
                color="white"
                @click="addIssueToRepair"
              ></v-btn>
            </div>
          </v-card-text>
        </div>
        <div class="text-right my-4 mx-5 ma-md-6 mt-4">
          <v-btn variant="text" @click="cancel"> Cancel </v-btn>
          <v-btn
            color="primary"
            class="ml-4"
            @click="createNewRepairConfirm"
            :loading="repairCreating"
            :disabled="repairCreating || !location"
          >
            Save
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </v-overlay>
</template>

<script setup lang="ts">
import RepairHelper from "@/helpers/repairHelper";
import { RepairStatus } from "@/types/RepairStatus";
import { UserPermissionType } from "@/types/UserPermissionType";
import userProfileService from "@/services/UserProfileService";
import { computed, ref, watch, onMounted, onUnmounted } from "vue";
import Issue from "@/types/Issue";
import RepairIssue from "@/types/RepairIssue";
import IssueSelect from "@/components/common/IssueSelect.vue";
import infoMessageService from "@/services/InfoMessageService";
import { InfoMessageType } from "@/types/InfoMessageType";
import Device from "@/types/Device";
import { IssueDeviceStatus } from "@/types/IssueDeviceStatus";
import issueResource from "@/resources/IssueResource";
import repairResource from "@/resources/RepairResource";
import locationResource from "@/resources/LocationResource";
import IssueHelper from "@/helpers/issueHelper";
import type { VDataTable } from "vuetify/components";
import ChangeManager from "@/services/ChangeManager";
import { useDisplay } from "vuetify";
import { useConfirm } from "@/services/ConfirmService";
import userStorage from "@/services/UserStorageService";

type ReadonlyHeaders = VDataTable["$props"]["headers"];

const emit = defineEmits(["update:show", "created"]);
const props = withDefaults(defineProps<{ device?: Device | null; show?: boolean }>(), {
  device: null,
  show: false,
});

const confirmDialog = useConfirm();

const show = computed({
  get() {
    return props.show;
  },
  set(value) {
    emit("update:show", value);
  },
});

const onCancel = () => {
  if (ChangeManager.state().isChanged) {
    ChangeManager.show();
    return;
  }

  show.value = false;
};
const status = ref(RepairStatus.Pending);
const errors = ref({ location: "" });

const notes = ref("");
const isMobileAndDown = useDisplay().smAndDown;

const repairCreating = ref(false);
const selectedIssue = ref<Issue | null>(null);
const issueSelectKey = ref<number>(new Date().getTime());
const locations = ref<string[]>([]);
const repairLocationKey = 'repairLocationKey'
const location = ref(userStorage.get(repairLocationKey) || null);

let changesControl: ChangeManager | null = null;

const headers: ReadonlyHeaders = [
  { title: "ID", align: "start", key: "issueId" },
  { title: "Title", key: "name" },
  { title: "Device issue status", key: "issueDeviceStatus", sortable: false },
  { title: "", key: "actions", sortable: false },
];

const relatedIssues = ref<RepairIssue[]>([]);
const issueTitle = ref("");

const getIssueDeviceStatusName = (status: IssueDeviceStatus) => {
  return IssueHelper.getDeviceStatusDisplayName(status);
};

const getIssueDeviceStatusColor = (status: IssueDeviceStatus) => {
  return IssueHelper.getDeviceStatusColor(status);
};

const getRepairStatusName = computed(() => {
  return RepairHelper.getRepairStatusDisplayName(status.value);
});

const statusColor = computed(() => {
  return RepairHelper.getRepairStatusColor(status.value);
});

const statusList = computed(() => {
  return Object.values(RepairStatus)
    .filter((v) => !isNaN(Number(v)))
    .map((v) => ({
      text: RepairHelper.getRepairStatusDisplayName(v as number),
      value: v as number,
    }))
    .filter(({ value }) => value !== RepairStatus.Completed);
});

const canAddRepair = computed(() => {
  return userProfileService.hasPermission(UserPermissionType.AddRepairs);
});

const canAddIssue = computed(() => {
  return userProfileService.hasPermission(UserPermissionType.AddIssues);
});

const canViewIssue = computed(() => {
  return userProfileService.hasPermission(UserPermissionType.ViewIssues);
});

const getLocations = async () => {
  locations.value = await locationResource.getLocations();
};

onMounted(() => {
  getLocations();
});

const updateStatus = (item: RepairStatus) => {
  status.value = item;
};

const cancel = () => {
  emit("update:show", false);
};

const deleteRelatedIssue = (index: number) => {
  relatedIssues.value = relatedIssues.value.filter((v: RepairIssue, ind) => index !== ind);
};

// begin change management
onUnmounted(() => {
  changesControl?.clearByStack();
});
watch(
  () => props.show,
  (val: boolean | undefined, oldValue: boolean | undefined) => {
    changesControl = ChangeManager.modalController({
      controller: changesControl,
      isNewValue: Boolean(val && !oldValue),
      isDestroy: Boolean(oldValue && !val),
      isUpdateValue: false,
      data: {
        repair: {
          repairId: 0,
          deviceId: props.device?.deviceId,
          status: status.value,
          result: 0,
          location: location.value,
          notes: notes.value,
          issues: relatedIssues.value,
        },
      },
      message: "You have unsaved Repair changes.",
      target: `new-repair`,
      onLeave: () => cancel(),
      onSave: () => createNewRepairConfirm(),
    });
  },
  { immediate: true }
);

const setChangesStatus = () => {
  const newRepair = {
    repairId: 0,
    deviceId: props.device?.deviceId,
    status: status.value,
    result: 0,
    location: location.value,
    notes: notes.value,
    issues: relatedIssues.value,
  };

  const origRepair = changesControl?.data?.origData?.repair;

  if (!changesControl || !origRepair) return;

  if (!ChangeManager.isObjectEqual(origRepair, newRepair || {}, { isOrigPartial: true })) {
    changesControl?.activate();
    return;
  }

  changesControl?.deactivate();
};

watch(() => [props.device?.deviceId, status.value, location.value, notes.value, relatedIssues.value], setChangesStatus, {
  deep: true,
});
// end change management

const addIssueToRepair = () => {
  if (selectedIssue.value) {
    if (relatedIssues.value.find((v: RepairIssue) => v.issueId === selectedIssue.value?.issueId)) {
      infoMessageService.show(InfoMessageType.Warning, "The issue is connected already.");
      return;
    }

    const issueStatus = IssueDeviceStatus.New;
    relatedIssues.value.push({ ...selectedIssue.value, issueDeviceStatus: issueStatus });
    selectedIssue.value = null;
    issueSelectKey.value = new Date().getTime();
  }

  if (issueTitle.value) {
    addNewIssue();
  }
};

const addNewIssue = () => {
  if (!canAddIssue.value || !issueTitle.value || !props.device?.deviceId) {
    return;
  }
  relatedIssues.value.push({ issueId: 0, name: issueTitle.value, issueDeviceStatus: IssueDeviceStatus.New });

  issueSelectKey.value = new Date().getTime();
  selectedIssue.value = null;
  issueTitle.value = "";
};

const createNewRepair = () => {
  if (!location.value) errors.value.location = "Location is required";
  if (!canAddRepair.value || !props.device?.deviceId || !location.value) {
    return;
  }

  // Save location
  userStorage.set(repairLocationKey, location.value);

  repairCreating.value = true;

  repairResource
    .addRepair({
      repairId: 0,
      deviceId: props.device?.deviceId,
      status: status.value,
      result: 0,
      location: location.value,
      notes: notes.value,
      issues: relatedIssues.value,
    })
    .then((resp) => {
      issueSelectKey.value = new Date().getTime();
      selectedIssue.value = null;
      issueTitle.value = "";
      show.value = false;
      emit("created");
    })
    .catch(issueResource.defaultErrorHandler)
    .finally(() => {
      repairCreating.value = false;
    });
};

const createNewRepairConfirm = () => {
  if (issueTitle.value || selectedIssue.value) {
    confirmDialog
      .show(`You have selected an issue, but did not add it.<br/><br/>Are you sure you want to continue?`, {
        buttonTrueText: "Continue",
        buttonFalseText: "Back",
        width: 500,
      })
      .then((confirmed) => {
        if (confirmed) {
          createNewRepair();
        }
      });
    return;
  }
  createNewRepair();
};
</script>
<style scoped>
.title {
  width: 100px;
  font-size: 13px;
}

.location {
  font-size: 13px;
  font-weight: 500;
}

.notes * {
  font-size: 13px;
}
</style>
